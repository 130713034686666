import React from "react";
import { Container, Image } from "react-bootstrap";
import crea_lobby from "../../images/COC_crea_lobby.png";


class CoopCup extends React.Component {
  render() {
    return (
      <div className="CoopCup">
        <header className="App-header">
          <h1>Coop Cup</h1>
        </header>
        <header className="App-body">
          <Container>
            <ol>
              {/* <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={presentazione}
                    alt="presentazione"
                  />
                </Carousel.Item>
              </Carousel> */}
              {/* <iframe src="" title="CommunityInvitational" width="100%" height="500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe> */}
              <br />
              <br />
              <h2>
                <li>Descrizione</li>
              </h2>
              La Coop Cup è un torneo 2vs2 (Modalità cooperativa) a eliminazione diretta.
              <br />
              Numero di team: 16
              <br />
              Map pool: Arabia, Arena, Mega-Casuale, Nascondiglio, Nomade
              <br />
              Civiltà: Random/Casuali
              <br />
              <br />
              L'idea del torneo è quella che una stessa civiltà venga utilizzata contemporaneamente da due giocatori, i quali condividono risorse, unità e edifici.
              <br />
              I due giocatori dovranno quindi essere in grado di dividersi i compiti per poter sopraffare i due avversari.
              <br />
              Idealmente un giocatore potrebbe dedicarsi solamente all'economia e l'altro esclusivamente alle unità militari.
              <br />
              <a
                href="https://forms.gle/eGkVsuA1JuCBoqyW9"
                target="_blank"
                rel="noreferrer"
              >
              Clicca qua per iscriverti!
              </a>
              <br />
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              I giocatori dovranno iscriversi in coppia per poter partecipare al torneo, rispettando le seguenti regole:
              <ul>
                <li>
                  Somma highest elo posseduti da entrambi deve essere inferiore a 2800
                </li>
                <li>
                  Appartenenza a due clan differenti (per favorire la coesione e cooperazione della community)
                </li>
              </ul>
              Lo Staff può aiutarvi a trovare un partner.
              <br />
              Ad ogni team viene assegnato un numero a seconda della somma degli highest elo posseduti sul sito https://aoe2.net/.
              <br />
              Al team con somma highest elo più alto viene assegnato il numero 1 e così via fino ad assegnare il numero 16 al team con somma highest elo più basso.
              <br />
              Lo Staff potrà modificare l'highest elo di un giocatore se questo non dovesse rispecchiare la realtà.
              <br />
              <br />
              <h2>
                <li>Formato Torneo</li>
              </h2>
              Verrà creato il tabellone delle eliminatorie nella seguente maniera:
              <ul>
                <li>1 - 9</li>
                <li>5 - 13</li>
                <li>3 - 11</li>
                <li>7 - 15</li>
                <li>2 - 10</li>
                <li>6 - 14</li>
                <li>4 - 12</li>
                <li>8 - 16</li>
              </ul>
              Le partite da disputare saranno: 8 ottavi, 4 quarti, 2 semifinali e 1 finale.
              <br />
              Nota bene: Il tabellone o il numero delle partite potrebbe variare a seconda del numero degli iscritti
              <br />
              Per ottavi, quarti e semifinale:
              <ul>
                <li>Sarà giocato un incontro diretto BO1 (solo andata)</li>
                <li>La mappa verrà scelta casualmente dal Map Pool</li>
              </ul>
              Sarà giocato un incontro diretto BO1 (solo andata)
              La mappa verrà scelta casualmente dal Map Pool
              <br />
              Per la finale:
              <ul>
                <li>Sarà giocato BO3 (al meglio di 3 partite)</li>
                <li>Il primo incontro si giocherà su Arabia.</li>
                <li>La mappa del secondo incontro sarà scelta dal perdente del primo incontro (Mappa all'interno del pool, Arabia esclusa).</li>
                <li>Qualora si dovesse tenere, la mappa del terzo incontro sarà scelta dal perdente del secondo incontro (Mappa all'interno del pool, Arabia e mappa del secondo incontro escluse).</li>
              </ul>
              <br />
              In caso di crash/disconnessione di un giocatore è consentito il restore per 3 volte. Se non è possibile in nessun modo il restore, la
              partita continuerà senza quel giocatore.
              <br />
              In caso di bug della mappa è consentito il restore fino
              al 12° minuto. Se non è possibile in nessun modo il restore, si
              procede con un restart.
              <br />
              <h2><li>Date</li></h2>
              ???:
              <ul>
                <li>ore 22:00 (+10min) si giocano i primi 4 Ottavi</li>
                <li>a seguire i secondi 4 ottavi</li>
                <li>a seguire 4 quarti</li>
              </ul>
              ???:
              <ul>
                <li>ore 22:00 (+10min) 2 semifinali</li>
                <li>a seguire Finale (BO3)</li>
              </ul>
              <br />
              Tutte le partite verranno giocate in diretta con un delay di 3 minuti.
              <br />
              L'evento sarà castato sul canale{" "}
              <a
                href="https://www.twitch.tv/aoeitalia"
                target="_blank"
                rel="noreferrer"
              >
                https://www.twitch.tv/aoeitalia
              </a>
              <br />
              <br />
              <h3><li>Lobby di gioco</li></h3>
              Per creare la lobby di gioco, dal menu principale seguire i seguenti passi:
              <ol>
                <li>Più giocatori</li>
                <li>Ospita partita</li>
                <li>Visibilità: Pubblica</li>
                <li>Giocatori: 4</li>
                <li>Consenti spettatori: Si</li>
                <li>Nascondi civiltà: No</li>
                <li>Ritardo spettatori: 3 min</li>
                <li>Crea lobby</li>
                <Image src={crea_lobby} alt="Crea lobby" fluid />
                <li>
                  In alto a destra: Copia id partita e invialo ai
                  Streamers/Casters
                </li>
                <li>Reimposta</li>
                <li>Seleziona Posizione: Costa</li>
                <li>Casuale</li>
                <li>Seleziona le mappe: Arabia, Arena, Mega-Casuale, Nascondiglio, Nomade</li>
                <li>Applica</li>
                <li>Dimensione mappa: 2</li>
                <li>Selezionare lo stesso colore del proprio compagno di squadra.</li>
                <li>Selezionare civiltà: "Casuale"</li>
              </ol>
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default CoopCup;
