import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faSkull
} from "@fortawesome/free-solid-svg-icons";

class StreakIcon extends React.Component {
  render() {
    if (this.props.diff >= 5) {
      return (
        <div>
          <FontAwesomeIcon icon={faFire} color="#ff0000" />{' '}
          {this.props.diff}
        </div>
      );
    } else if (this.props.diff <= -5) {
      return (
        <div>
          <FontAwesomeIcon icon={faSkull} color="#808080" />{' '}
          {this.props.diff}
        </div>
      );
    }
    else {
      return (
        <div>
          {this.props.diff}
        </div>
      );
    }
  }
}

export default StreakIcon;