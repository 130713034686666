import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import AlertDismissible from "../components/AlertDismissible";

class RegisterPage extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      username: "",
      password: "",
      confirmedPassword: "",
      platform: "STEAM",
      nationality: "Italy",
      error_message_variant: "",
      error_message_title: "",
      error_message_text: "",
      error_message_visible: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/auth/signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state),
    })
      .then((response) => {
        if (response.ok) {
          this.props.history.push({
            pathname: "/login",
            state: { fromRegisterPage: true },
          });
        } else {
          return response.json();
        }
      })
      .then((jsonResult) => {
        let errors_list = jsonResult.errors.map((error, index) => {
          return (
            <li key={index}>{`${error.field}: ${error.defaultMessage}`}</li>
          );
        });
        this.setState({
          error_message_variant: "danger",
          error_message_title: "Registration Error!",
          error_message_text: errors_list,
          error_message_visible: true,
        });
      })
      .catch((error) =>
        this.setState({
          error_message_variant: "danger",
          error_message_title: "Login Error!",
          error_message_text: "Something went wrong",
          error_message_visible: true,
        })
      );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <div className="RegisterPage">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group as={Col} controlId="email">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Email address
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="email"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="username">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Username
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="Username"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="password">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Password
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="confirmedPassword">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Confirm Password
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="platform">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Piattaforma
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control as="select" onChange={this.handleChange}>
                <option>STEAM</option>
                <option>XBOX</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="nationality">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Nazionalità
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control as="select" onChange={this.handleChange}>
                {/* https://restcountries.eu/rest/v2/all */}
                <option>Italia</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <AlertDismissible
                error_message_variant={this.state.error_message_variant}
                error_message_title={this.state.error_message_title}
                error_message_text={this.state.error_message_text}
                error_message_visible={this.state.error_message_visible}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <Button type="submit" variant="outline-info">
                Register
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default RegisterPage;
