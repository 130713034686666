import React from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faTwitch,
  faYoutube,
  faFacebook,
  faEtsy,
  faEdge,
  faPatreon,
  faWhatsappSquare,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

class SocialCard extends React.Component {
  render() {
    let socialIcon;
    switch (this.props.title) {
      case "Discord":
        socialIcon = (
          <FontAwesomeIcon
            size="5x"
            icon={faDiscord}
            color={this.props.color}
          />
        );
        break;
      case "Twitch":
        socialIcon = (
          <FontAwesomeIcon size="5x" icon={faTwitch} color={this.props.color} />
        );
        break;
      case "Youtube":
        socialIcon = (
          <FontAwesomeIcon
            size="5x"
            icon={faYoutube}
            color={this.props.color}
          />
        );
        break;
      case "Facebook":
        socialIcon = (
          <FontAwesomeIcon
            size="5x"
            icon={faFacebook}
            color={this.props.color}
          />
        );
        break;
      case "Etsy":
        socialIcon = (
          <FontAwesomeIcon size="5x" icon={faEtsy} color={this.props.color} />
        );
        break;
      case "Telegram":
        socialIcon = (
          <FontAwesomeIcon
            size="5x"
            icon={faTelegram}
            color={this.props.color}
          />
        );
        break;
      case "WhatsApp":
        socialIcon = (
          <FontAwesomeIcon
            size="5x"
            icon={faWhatsappSquare}
            color={this.props.color}
          />
        );
        break;
      case "Patreon":
        socialIcon = (
          <FontAwesomeIcon
            size="5x"
            icon={faPatreon}
            color={this.props.color}
          />
        );
        break;
      default:
        socialIcon = (
          <FontAwesomeIcon size="5x" icon={faEdge} color={this.props.color} />
        );
        break;
    }
    return (
      <Card style={{ width: "18rem" }} text="dark">
        {socialIcon}
        <Card.Body>
          <Card.Title>{this.props.title}</Card.Title>
          <Card.Text>{this.props.text}</Card.Text>
          <Button
            variant={this.props.button_variant}
            href={this.props.link}
            target="_blank"
            rel="noreferrer"
          >
            {this.props.button_text}
          </Button>
        </Card.Body>
      </Card>
    );
  }
}

export default SocialCard;
