import React from "react";
import MaterialTable from "material-table";

class LeaderBoardCompact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  refreshTable() {
    fetch(process.env.REACT_APP_BACKEND_HOST + `/api/getCompactPlayers`)
      .then((response) => response.json())
      .then((players) =>
        this.setState({
          data: players.sort(function (a, b) { return parseInt(b.rating_rm_1vs1 + b.rating_qm_2vs2 + b.rating_qm_3vs3 + b.rating_qm_4vs4) - parseInt(a.rating_rm_1vs1 + a.rating_qm_2vs2 + a.rating_qm_3vs3 + a.rating_qm_4vs4) }),
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.refreshTable();
  }

  columns = [
    {
      field: 'discord_avatar',
      title: '',
      sorting: false,
      grouping: false,
      align: "right",
      render: rowData =>
        rowData.discord_avatar ?
          <img src={`https://cdn.discordapp.com/avatars/${rowData.discord_id}/${rowData.discord_avatar}.webp?size=1024`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://cdn.discordapp.com/embed/avatars/1.png?size=1024";
            }
            }
            alt="avatar"
            style={{ width: 25, borderRadius: '50%' }}
          /> :
          <img src={`https://cdn.discordapp.com/embed/avatars/0.png?size=1024`}
            alt="avatar"
            style={{ width: 25, borderRadius: '50%' }}
          />
    },
    {
      title: "Utente Discord", field: "discord_name", type: "numeric", align: "left",
      render: rowData => <a
        href={`discord://discordapp.com/users/${rowData.discord_id}/`}
        target="_blank"
        rel="noreferrer"
      >
        {rowData.discord_name}
      </a>
    },
    {
      title: "Utente AoE", field: "name",
      render: rowData => <a
        href={`http://aoe4world.com/players/${rowData.profile_id}`}
        target="_blank"
        rel="noreferrer"
      >
        {rowData.name}
      </a>
    },
    //{
    //  title: "Ranking Points (Partite) RM 1vs1", field: "rating_rm_1vs1",
    //  render: rowData => <div>{rowData.rating_rm_1vs1}
    //    <small> ({rowData.games_rm_1vs1})</small>
    //  </div>
    //},
    {
      title: "Elo (Partite) QM 1vs1", field: "rating_qm_1vs1",
      render: rowData => <div>{rowData.rating_qm_1vs1}
        <small> ({rowData.games_qm_1vs1})</small>
      </div>
    },
    {
      title: "Elo (Partite) QM 2vs2", field: "rating_qm_2vs2",
      render: rowData => <div>{rowData.rating_qm_2vs2}
        <small> ({rowData.games_qm_2vs2})</small>
      </div>
    },
    {

      title: "Elo (Partite) QM 3vs3", field: "rating_qm_3vs3",
      render: rowData => <div>{rowData.rating_qm_3vs3}
        <small> ({rowData.games_qm_3vs3})</small>
      </div>
    },
    {
      title: "Elo (Partite) QM 4vs4", field: "rating_qm_4vs4",
      render: rowData => <div>{rowData.rating_qm_4vs4}
        <small> ({rowData.games_qm_4vs4})</small>
      </div>
    },
  ];

  render() {
    return (
      <div className="LeaderBoard">
        <MaterialTable
          columns={this.columns}
          data={this.state.data}
          title=""
          options={{
            exportButton: true,
            fixedColumns: true,
            paging: true,
            pageSize: 75,
            emptyRowsWhenPaging: true,
            pageSizeOptions: [25, 50, 75, 100],
            maxBodyHeight: "900px",
            headerStyle: {
              backgroundColor: "#24a0ed",
              color: "white",
              padding: 5,
            },
            rowStyle: (data, index) => {
              if (index % 2) {
                return { backgroundColor: "#f8f8f8" };
              }
            },
            cellStyle: { padding: 8 },
          }}
          actions={[
            {
              icon: "refresh",
              isFreeAction: true,
              onClick: (event) => this.refreshTable(),
            }
          ]}
        />
      </div>
    );
  }
}

export default LeaderBoardCompact;
