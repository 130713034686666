import React from "react";

class RankLevelIcon extends React.Component {
  render() {
    if (this.props.rank_level === "undefined") {
      return <img
        className="rank-icon"
        src="https://aoeitalia.com/aoe4/ranks/unranked.png"
        alt="rank"
        title="rank"
      />;
    } else return <img
      className="rank-icon"
      src={"https://aoeitalia.com/aoe4/ranks/" + this.props.rank_level + ".png"}
      alt={this.props.rank_level}
      title={this.props.rank_level}
    />;
  }
}

export default RankLevelIcon;
