import React from "react";
import { Container } from "react-bootstrap";


class TorneoDei3Campioni extends React.Component {
  render() {
    return (
      <div className="TorneoDei3Campioni">
        <header className="App-header">
          <h1>Torneo Dei 3 Campioni</h1>
          <h5>Age of Empires 4</h5>
          <h5>(Organizzato da rajAs#6944)</h5>
        </header>
        <header className="App-body">
          <Container>
            <br />
            <br />
            Si individuano 3 campioni: chi vorrà potrà lanciare loro una sfida 1vs1.
            <br />
            Ogni sfidante decide a chi dei 3 lanciarla, ma solo ad uno!
            <br />
            Gli sfidanti si presenteranno in ordine di elo (hidden mmr) dal più basso al più alto.
            <br />
            Se uno sfidante batte il campione, acquisisce il suo ruolo e si sostituirà a lui nella corsa verso la finale.
            <br />
            <br />
            Cosa succede se il numero di sfidanti è sproporzionato?
            <br />
            Per ogni 3 sfidanti in più rispetto al campione con meno sfidanti, il campione avrà accesso a diversi livelli di benefit (non cumulabili):
            <ol>
              <li>(almeno 3 sfidanti in più): scelta della mappa. </li>
              <li>(almeno 6 sfidanti in più): ban di una civ all'avversario. </li>
            </ol>
            Ottenuto il livello 2 il campione potrà scegliere quale dei due bonus applicare ad ogni sfida, ma mai entrambi.
            <br />
            <br />
            Map pool: Arabia, Re della collina, Monti e valli, Lipany, Megarandom
            <br />
            Map pick:
            Ognuno bannerà due mappe. Quindi rimarrà solo la mappa su cui giocare.{' '}
            <a
              href="https://aoe2cm.net/preset/qUQoq"
              target="_blank"
              rel="noreferrer"
            >
              https://aoe2cm.net/preset/qUQoq
            </a>
            <br />
            <br />
            Dopo aver sconfitto tutti gli sfidanti, i 3 campioni disputeranno una ffa nomad megarandom per provare le loro abilità su un terreno impervio e definire il campione tra i campioni!
            <br />
            Dunque! A chi vuoi gettare il guanto della sfida?
            <br />
            <br />
            <br />
          </Container>
        </header>
      </div>
    );
  }
}

export default TorneoDei3Campioni;
