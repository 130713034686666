import React from "react";
import { Navbar, Container } from "react-bootstrap";

class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <Navbar sticky="bottom">
          <Container fluid>
            <p>
              Powered by{" "}
              <a
                href="https://www.linkedin.com/in/vincenzo-cani-b9b85234/"
                target="_blank"
                rel="noreferrer"
              >
                Vincenzo Cani
              </a>
              <br />
              <i>
                Age of Empires II: HD©, Age of Empires: Definitive Edition©, Age of Empires II: Definitive Edition©, Age of Empires III: Definitive Edition© and Age of Empires IV© Microsoft Corporation.<br />
                AoeItalia.com was created under Microsoft's "
                <a
                  href="https://www.xbox.com/en-US/developers/rules"
                  target="_blank"
                  rel="noreferrer"
                >
                  Game Content Usage Rules
                </a>
                " using assets from Age of Empires II: Definitive Edition, and it is
                not endorsed by or affiliated with Microsoft.
              </i>
            </p>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Footer;
