import React from "react";
import MaterialTable from "material-table";
import { Badge } from "react-bootstrap";
import DiffArrow from "./DiffArrow";

class LeaderBoardSingleAoe2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  apiUrl = "https://api.aoeitalia.com/v1/player";

  refreshTable() {
    fetch(this.apiUrl)
      .then((response) => response.json())
      .then((data) => data.data)
      .then((players) =>
        this.setState({
          data: players.sort(function (a, b) { return parseInt(b.elo_1v1) - parseInt(a.elo_1v1) }),
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.refreshTable();
  }

  columns = [
    {
      title: "#",
      type: "numeric",
      sorting: false,
      render: (rowData, row) => {
        return <div> {rowData.tableData.id + 1}</div>
      },
    },
    { title: "Nome", field: "username" },
    { title: "Rating", field: "elo_1v1", type: "numeric", defaultSort: "desc" },
    {
      title: "",
      type: "numeric",
      sorting: false,
      render: (rowData) => {
        var diff = rowData.elo_1v1 - rowData.prev_1v1;
        return <DiffArrow diff={diff} />;
      },
    },
    { title: "Max Rating", field: "top_1v1", type: "numeric" },
    { title: "Partite", field: "games_1v1", type: "numeric" },
    { title: "Ultima partita", field: "lastdt_1v1" },
    {
      title: "Ruolo",
      field: "role_name",
      sorting: false,
      render: (rowData) => {
        return (
          <Badge
            className="badge"
            bsPrefix={rowData.role_name.replace(/\s+/g, "")}
            variant="primary"
          >
            {rowData.role_name}
          </Badge>
        );
      },
    },
  ];

  render() {
    return (
      <div className="LeaderBoard">
        <MaterialTable
          columns={this.columns}
          data={this.state.data}
          title=""
          options={{
            fixedColumns: true,
            paging: true,
            pageSize: 25,
            emptyRowsWhenPaging: true,
            pageSizeOptions: [25, 50, 75, 100],
            maxBodyHeight: "900px",
            headerStyle: {
              backgroundColor: "#24a0ed",
              color: "white",
              padding: 5,
            },
            rowStyle: (data, index) => {
              if (index % 2) {
                return { backgroundColor: "#f8f8f8" };
              }
            },
            cellStyle: { padding: 8 },
          }}
          actions={[
            {
              icon: "refresh",
              isFreeAction: true,
              onClick: (event) => this.refreshTable(),
            },
          ]}
        />
      </div>
    );
  }
}

export default LeaderBoardSingleAoe2;
