import React from "react";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { faVimeo } from "@fortawesome/free-brands-svg-icons";
class ClansTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  apiUrl = process.env.REACT_APP_BOT_HOST + "/clanMembers";

  refreshTable() {
    fetch(this.apiUrl)
      .then((response) => response.json())
      .then((clans) =>
        this.setState({
          data: clans,
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.refreshTable();
  }
  columns = [
    {
      title: "Clan",
      field: "clan",
      defaultGroupSort: "asc",
      defaultGroupOrder: 0
    },
    {
      field: 'avatar_url',
      title: '',
      sorting: false,
      grouping: false,
      align: "right",
      render: rowData => <img src={rowData.avatar_url} alt="avatar" style={{ width: 25, borderRadius: '50%' }} />
    },
    {
      title: "Nome", field: "display_name", grouping: false, defaultSort: "asc",
      render: rowData => <div>{rowData.display_name}</div>
    },
    {
      title: "", field: "captain",
      render: (rowData) => {
        if (rowData.captain === true) {
          return (
            <div>
              <FontAwesomeIcon icon={faCopyright} color="#000000" />
            </div>
          );
        } else if (rowData.vicecaptain === true) {
          return (
            <div>
              <FontAwesomeIcon icon={faVimeo} color="#000000" />
            </div>
          );
        }
      },
    },

    // {
    //   title: "# Membri",
    //   type: "numeric", align: "left",
    //   render: (rowData, row) => {
    //     return <div> {rowData.members.length}</div>;
    //   },
    //   customSort: (a, b) => a.members.length - b.members.length,
    // },
  ];

  render() {
    return (
      <div className="ClansTable">
        <MaterialTable
          columns={this.columns}
          data={this.state.data}
          title=""
          options={{
            paging: true,
            pageSize: 25,
            emptyRowsWhenPaging: true,
            pageSizeOptions: [25, 50, 75, 100],
            maxBodyHeight: "600px",
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#24a0ed",
              color: "white",
              padding: 5,
            },
            rowStyle: (data, index) => {
              if (index % 2) {
                return { backgroundColor: "#f8f8f8" };
              }
            },
            cellStyle: { padding: 8 },
          }}
          actions={[
            {
              icon: "refresh",
              isFreeAction: true,
              onClick: (event) => this.refreshTable(),
            },
            // {
            //   icon: 'search',
            //   tooltip: 'aoe2.net profile',
            //   onClick: (event, rowData) => alert("discord id: " + rowData.id)
            // },
          ]}
        />
      </div>
    );
  }
}

export default ClansTable;
