import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR } from "../actions";

const initialState = {
  error_message_variant: "",
  error_message_title: "",
  error_message_text: "",
  error_message_visible: false,
  isLoading: false,
};

const loggedReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      const isLoadingTrue = { isLoading: true };
      return initialState.concat(isLoadingTrue);
    case LOGIN_SUCCESS:
      const isLoadingFalse = { isLoading: false };
      return initialState.concat(isLoadingFalse);
    case LOGIN_ERROR:
      return {
        error_message_variant: "danger",
        error_message_title: "Login Error!",
        error_message_text: action.payload,
        error_message_visible: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default loggedReducer;
