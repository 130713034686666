import React from "react";
import { Container } from "react-bootstrap";


class DeathTournamentWFD extends React.Component {
  render() {
    return (
      <div className="DeathTournamentWFD">
        <header className="App-header">
          <h1>1v1 Death Tournament WFD</h1>
          <h5>(Organizzato da Marcotamby)</h5>
          <h5>Age of Empires IV</h5>
        </header>
        <header className="App-body">
          <Container>
            <iframe
              src={"https://drive.google.com/file/d/1V849UOLkVgIga2zwwM0-HaboxeVdHt2H/preview"}
              title="1v1 Death Tournament WFD"
              width="100%"
              height="700"
            ></iframe>
          </Container>
        </header>
      </div>
    );
  }
}

export default DeathTournamentWFD;
