import React from "react";
import { useParams } from "react-router-dom";
import Countdown from 'react-countdown';


function CountdownTimer() {
  const { id } = useParams();
  function displayCountdown() {
    return (
      <div className="Countdown">
        <header className="App-header">
          <h1>
            <Countdown date={Date.now() + id*1000}  daysInHours={true} />
          </h1>          
        </header>
      </div>
    );
  }

  return displayCountdown();

}

export default CountdownTimer;
