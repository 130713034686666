import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faChalkboardTeacher,
  faLayerGroup,
  faRobot,
  faTrophy,
  faHeadset,
  faCampground,
  faBroadcastTower,
} from "@fortawesome/free-solid-svg-icons";

class InfoCard extends React.Component {
  render() {
    let icon;
    switch (this.props.title) {
      case "Clan":
        icon = (
          <FontAwesomeIcon
            size="5x"
            icon={faCampground}
            color={this.props.color}
          />
        );
        break;
      case "Coaching":
        icon = (
          <FontAwesomeIcon
            size="5x"
            icon={faChalkboardTeacher}
            color={this.props.color}
          />
        );
        break;
      case "Ranghi":
        icon = (
          <FontAwesomeIcon
            size="5x"
            icon={faLayerGroup}
            color={this.props.color}
          />
        );
        break;
      case "Bot":
        icon = (
          <FontAwesomeIcon size="5x" icon={faRobot} color={this.props.color} />
        );
        break;
      case "Tornei":
        icon = (
          <FontAwesomeIcon size="5x" icon={faTrophy} color={this.props.color} />
        );
        break;
      case "Chat Vocali":
        icon = (
          <FontAwesomeIcon
            size="5x"
            icon={faHeadset}
            color={this.props.color}
          />
        );
        break;
      case "Combriccola":
        icon = (
          <FontAwesomeIcon size="5x" icon={faUsers} color={this.props.color} />
        );
        break;
      case "Casting":
        icon = (
          <FontAwesomeIcon
            size="5x"
            icon={faBroadcastTower}
            color={this.props.color}
          />
        );
        break;
      default:
        icon = (
          <FontAwesomeIcon size="5x" icon={faUsers} color={this.props.color} />
        );
        break;
    }
    return (
      <Card style={{ width: "18rem", opacity: "0.75" }} text="dark">
        <Card.Body>
          {icon}
          <Card.Title>{this.props.title}</Card.Title>
          <Card.Text>{this.props.text}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default InfoCard;
