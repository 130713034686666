import React from "react";
import { Container } from "react-bootstrap";
import ClansTable from "../components/ClansTable";

class ClanPage extends React.Component {
  render() {
    return (
      <div className="ClanPage">
        <header className="App-header">
          <h1>Clans</h1>
        </header>
        <header className="App-body">
          <Container>
            <ClansTable />
          </Container>
          <br />
        </header>
      </div>
    );
  }
}

export default ClanPage;
