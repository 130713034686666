import React from "react";
import { useState, useEffect } from "react";
import { Table, Container, Figure, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Crown from "./Crown";
import DiffArrow from "./DiffArrow";

function MatchDetails() {
  const [match, setMatch] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    retrieveMatchDetails();
  });

  function retrieveMatchDetails() {
    fetch(`https://api.aoeitalia.com/v1/match/get/${id}`)
      .then((res) => res.json())
      .then((result) => {
        setMatch(result.data);
      });
  }

  function displayMatchDetails() {
    if (match) {
      return (
        <div className="MatchDetails">
          <header className="App-header">
            <h1>Dettagli Partita</h1>
          </header>
          <header className="App-body">
            <Container>
              <h4>ID Partita: {match.id}</h4>
              <h4>Nome Lobby: {match.name}</h4>
              <h4>Mappa: {match.map}</h4>
              {/*map can be null */}
              {<Figure>
                <Figure.Image
                  alt="Map"
                  src={
                    "https://aoe2.club/assets/images/maps/" + match.map_id + ".png"
                  }
                />
              </Figure>}
              <Row>
                <Col>
                  <Table responsive="sm">
                    <thead>
                      <tr>
                        <th colspan="4">Team 1</th>
                      </tr>
                    </thead>
                    <tbody>
                      {match.players.team_1.map((index) => (
                        <tr>
                          <td className={"PlayerBox Player" + index.color}>
                            {index.color}
                          </td>
                          <td>
                            {" "}
                            {index.username} <Crown win={index.win} />
                          </td>
                          <td> {index.current_elo} </td>
                          <td>
                            <DiffArrow diff={index.gain} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col>
                  <Table responsive="sm">
                    <thead>
                      <tr>
                        <th colspan="4">Team 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {match.players.team_2.map((index) => (
                        <tr>
                          <td className={"PlayerBox Player" + index.color}>
                            {index.color}
                          </td>
                          <td>
                            {" "}
                            {index.username} <Crown win={index.win} />
                          </td>
                          <td> {index.current_elo} </td>
                          <td>
                            <DiffArrow diff={index.gain} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </header>
        </div>
      );
    } else {
      return <div>Loading Match...</div>;
    }
  }
  return displayMatchDetails();
}

export default MatchDetails;
