import React from "react";
import { Alert } from "react-bootstrap";

class AlertDismissible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_alert: false,
    };
  }

  //UNSAFE
  componentWillReceiveProps(props) {
    this.setState({ show_alert: props.error_message_visible });
  }

  render() {
    const handleDismiss = () => this.setState({ show_alert: false });
    if (this.state.show_alert) {
      return (
        <Alert
          variant={this.props.error_message_variant}
          onClose={handleDismiss}
          dismissible
        >
          <Alert.Heading>{this.props.error_message_title}</Alert.Heading>
          <p>{this.props.error_message_text}</p>
        </Alert>
      );
    }
    return null;
  }
}

export default AlertDismissible;
