import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import SocialCard from "../components/SocialCard";
import { } from "@fortawesome/free-solid-svg-icons";
import { } from "@fortawesome/free-regular-svg-icons";

class SocialsPage extends React.Component {
  render() {
    return (
      <div className="SocialsPage">
        <header className="App-header">
          <br />
          <h1>Seguici sui nostri Social Network:</h1>
          <br />
        </header>
        <header className="App-body" >
          <Container>
            <Row xs={1} md={2} lg={4}><Col>
              <SocialCard
                color="#7289d9"
                title="Discord"
                text="Per comunicare durante la partita"
                button_variant="primary"
                link={process.env.REACT_APP_FRONTEND_HOST + "/discord"}
                button_text="Entra"
              ></SocialCard></Col><Col>
                <SocialCard
                  color="#6441a5"
                  title="Twitch"
                  text="Stream live dei giocatori italiani"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/twitch"}
                  button_text="Entra"
                ></SocialCard></Col><Col>
                <SocialCard
                  color="#0088cc"
                  title="Telegram"
                  text="Per tenerci in contatto"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/telegram"}
                  button_text="Entra"
                ></SocialCard></Col><Col>
                <SocialCard
                  color="#c4302b"
                  title="Youtube"
                  text="Video e guide per non smettere di imparare"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/youtube"}
                  button_text="Entra"
                ></SocialCard></Col><Col>
                <SocialCard
                  color="#3b5998"
                  title="Facebook"
                  text="Per tenerci in contatto e condividere meme"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/facebook"}
                  button_text="Entra"
                ></SocialCard></Col><Col>
                <SocialCard
                  color="#eb6d20"
                  title="Etsy"
                  text="Per acquistare i nostri gadget personalizzati"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/shop"}
                  button_text="Entra"
                ></SocialCard></Col><Col>
                <SocialCard
                  color="#25d366"
                  title="WhatsApp"
                  text="Per tenerci in contatto"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/whatsapp"}
                  button_text="Entra"
                ></SocialCard></Col><Col>
                <SocialCard
                  color="#f96854"
                  title="Patreon"
                  text="Per supportarci e farci crescere"
                  button_variant="primary"
                  link={process.env.REACT_APP_FRONTEND_HOST + "/patreon"}
                  button_text="Entra"
                ></SocialCard></Col>
            </Row>
            <br />
          </Container>
        </header>
      </div>
    );
  }
}

export default SocialsPage;
