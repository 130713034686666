import React from "react";
import { Container } from "react-bootstrap";

class Aoe4 extends React.Component {
  render() {
    return (
      <div className="Aoe4">
        <header className="App-header">
          <h1>Guide</h1>
          <h5>(Sezione In Aggiornamento)</h5>
        </header>
        <header className="App-body">
          <Container>
            <h2>Primi Passi: Video Introduttivi</h2>
            Per chi si avvicina per la prima volta ad Age of Empires o agli RTS
            in generale.
            <ul>
              <li>
                {" "}
                DadoBax - Le Basi:{" "}
                <a
                  href="https://www.youtube.com/watch?v=dgdqgitgiWU"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>
              <li>
                {" "}
                DadoBax - Passare all'Età Feudale in 5 minuti:{" "}
                <a
                  href="https://www.youtube.com/watch?v=CMrcqjrq13o"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>              <li>
                {" "}
                DadoBax - Come Vincere la tua Prima Partita Multiplayer:{" "}
                <a
                  href="https://www.youtube.com/watch?v=LkJaDvZaeO4"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>              <li>
                {" "}
                DadoBax - 10 Consigli per Migliorare:{" "}
                <a
                  href="https://www.youtube.com/watch?v=GrdcPVv2-_E"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>
              <li>
                {" "}
                Eog - Cosa é un build order? :{" "}
                <a
                  href="https://www.youtube.com/watch?v=O2y5FRpX4_g&list=PLvyoYsGqDJTVpsNK5sSSc-qZj5OfFOipq&ab_channel=EOG-TheItalianAOE4Caster"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>
              <li>
                {" "}
                Eog - Tasti di scelta rapidi:{" "}
                <a
                  href="https://youtu.be/aK56KIR7iKs"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>
              <li>
                {" "}
                Eog - Archer Rush con Inglesi al minuto 4:{" "}
                <a
                  href="https://www.youtube.com/watch?v=mf1FXptBTAc"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>
              <li>
                {" "}
                Eog - Knight Rush con Francesi al minuto 4:{" "}
                <a
                  href="https://www.youtube.com/watch?v=bw2aBacdYho"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>
              </li>
            </ul>
            <h2>Primi Passi: Guide Cartecee</h2>
            <ul>

              <li>
                {" "}
                Grafico semplice sui counter delle unità:{" "}
                <a
                  href="https://i.redd.it/wr5wsotq08w71.jpg"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
              <li>
                {" "}
                Grafico completo sui counter delle unità:{" "}
                <a
                  href="https://forums.ageofempires.com/uploads/default/original/3X/7/4/748fb982dc39a9ff1f3d61bb51c1de2f0a73ed25.jpeg"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
            </ul>
            <h2>I Build Orders: Video Avanzati</h2>
            <ul>
              <li>
                {" "}
                Ottomani di Fuguee:{" "}
                <a
                  href="https://www.youtube.com/playlist?list=PLFZQ1GfOnJMUOMKtCUxjAJbrqKDGXYzip"
                  target="_blank"
                  rel="noreferrer"
                >
                  playlist
                </a>
              </li>
              <li>
                {" "}
                Rus di Fuguee:{" "}
                <a
                  href="https://www.youtube.com/playlist?list=PLFZQ1GfOnJMUHfiWm_wNBTSEoqsFwEosh"
                  target="_blank"
                  rel="noreferrer"
                >
                  playlist
                </a>
              </li>
              <li>
                {" "}
                Inglesi di Fuguee:{" "}
                <a
                  href="https://www.youtube.com/playlist?list=PLFZQ1GfOnJMWQX46M1pzjaBvukLT6ljsS"
                  target="_blank"
                  rel="noreferrer"
                >
                  playlist
                </a>
              </li>
              <li>
                {" "}
                Sacro Romano Impero di Fuguee:{" "}
                <a
                  href="https://www.youtube.com/playlist?list=PLFZQ1GfOnJMV8mw6DE485ML_T12EiGOeK"
                  target="_blank"
                  rel="noreferrer"
                >
                  playlist
                </a>
              </li>
              <li>
                {" "}
                Abbasidi di Fuguee:{" "}
                <a
                  href="https://www.youtube.com/playlist?list=PLFZQ1GfOnJMW4FqQIbeVi6ScZnyG1hA9m"
                  target="_blank"
                  rel="noreferrer"
                >
                  playlist
                </a>
              </li>
              <li>
                {" "}
                Francesi di Fuguee:{" "}
                <a
                  href="https://www.youtube.com/playlist?list=PLFZQ1GfOnJMU2MUvKQE0tSpCpCNl9n0WB"
                  target="_blank"
                  rel="noreferrer"
                >
                  playlist
                </a>
              </li>

            </ul>
            <h2>I Build Orders: Guide in Inglese</h2>
            <ul>
              <li>
                {" "}
                Build Order Tool:{" "}
                <a
                  href="https://age4builder.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
              <li>
                {" "}
                AOE4 Builds:{" "}
                <a
                  href="https://www.aoeivbuilds.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
            </ul>
          </Container>
        </header>
      </div>
    );
  }
}

export default Aoe4;
