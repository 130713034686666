import React from "react";
import logo from "../logo.png";
import { Button, Container, Dropdown, DropdownButton, ButtonGroup, Row, Col } from "react-bootstrap";
import InfoCard from "../components/InfoCard";
import background from "../images/AoeItaliaBanner1.jpg";


class HomePage extends React.Component {
  render() {
    return (
      <div className="HomePage" style={{
        backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
      }}>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div class="container-fluid text-black p-5">
            <div class="container bg-white p-5  opacity-75">
              <h1>Benvenuto su {process.env.REACT_APP_WEBSITE_NAME}</h1>
              <p>
                Aoeitalia.com è la più grande community italiana dedicata a Age Of Empires!
                <br />
                L'obiettivo è quello di promuovere un ambiente tranquillo dove
                giocare.
                <br />
                Con noi potrai divertirti, con la possibilità di approfondire il
                lato competitivo del gioco.
              </p>
              <p>
                <Button
                  variant="primary"
                  href="http://discord.aoeitalia.com"
                  target="_blank"
                >
                  Entra nel nostro server Discord
                </Button>{" "}
                <Button
                  variant="secondary"
                  href="https://support.discord.com/hc/it/articles/360033931551-Cominciamo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Non hai Discord?
                </Button>{" "}
                <DropdownButton variant="danger" as={ButtonGroup} title="Non hai il gioco?" id="bg-nested-dropdown">
                  <Dropdown.Item variant="danger"
                    href="https://www.instant-gaming.com/it/4820-comprare-steam-age-of-empires-ii-definitive-edition-steam-edition-pc-gioco-steam/?igr=aoeitalia"
                    target="_blank"
                    rel="noreferrer">Age of Empires 2 DE</Dropdown.Item>
                  <Dropdown.Item variant="danger"
                    href="https://store.steampowered.com/app/1466860/Age_of_Empires_IV/"
                    target="_blank"
                    rel="noreferrer">Age of Empires 4</Dropdown.Item>
                  <Dropdown.Item variant="danger"
                    href="https://www.cdkeys.com/age-of-empires-iv-pc-steam?mw_aref=AoeItalia"
                    target="_blank"
                    rel="noreferrer">Age of Empires 4 (cdkeys)</Dropdown.Item>
                  <Dropdown.Item variant="danger"
                    href="https://www.instant-gaming.com/it/2244-comprare-age-of-empires-iv-pc-gioco-steam/?igr=aoeitalia"
                    target="_blank"
                    rel="noreferrer">Age of Empires 4 (Instant Gaming)</Dropdown.Item>
                </DropdownButton>
              </p>
            </div>
          </div >
        </header >
        <header className="App-body">
          <Container>
            <Row xs={1} md={2} lg={4}>
              <Col>
                <InfoCard
                  color="#000000"
                  title="Chat Vocali"
                  text="Utilizziamo le chat vocali di Discord per comunicare durante le partite e per organizzarle."
                ></InfoCard>
              </Col>
              <Col>

                <InfoCard
                  color="#008000"
                  title="Combriccola"
                  text="Siete già un gruppetto di amici? Unitevi al nostro server discord, creeremo per voi dei canali vocali privati. Da noi potrete fondare un vostro Clan, reclutare nuovi compagni e partecipare a competizioni!"
                ></InfoCard>    </Col>
              <Col>

                <InfoCard
                  color="#4169e1"
                  title="Coaching"
                  text="Entra in un canale vocale e osserva le partite di giocatori esperti.
                                    Non avranno alcun problema a rispondere alle tue domande!"
                ></InfoCard>    </Col>
              <Col>

                <InfoCard
                  color="#964B00"
                  title="Clan"
                  text="Cerchi Clan? scrivi nel canale #⛺-reclutamento.
                                Ricorda che il primo canale vocale di ogni Clan é accessibile liberamente."
                ></InfoCard>    </Col>

              <Col>              <InfoCard
                color="#FFD700"
                title="Tornei"
                text="Vuoi partecipare a tornei? Controlla nel canale #📢-annunci, se stiamo organizzando qualcosa."
              ></InfoCard></Col>
              <Col>
                <InfoCard
                  color="#C0C0C0"
                  title="Bot"
                  text="Abbiamo creato un bot per voi! Usalo nel canale #🤖-bot-spam per bilanciare le squadre di una stanza. Usa il comando !help per scoprire tutte le funzionalità."
                ></InfoCard></Col>
              <Col>
                <InfoCard
                  color="#6495ed"
                  title="Casting"
                  text="Trasmettiamo e commentiamo partite di tornei o amichevoli sul nostro canale Twitch. Vuoi essere telecronista o protagonista dei nostri video? Contatta lo @staff"
                ></InfoCard></Col>
              <Col>
                <InfoCard
                  color="#8b008b"
                  title="Ranghi"
                  text="I giocatori vengono classificati per ranghi che rispecchiano il loro livello di gioco 1vs1.
                                    Tagga il rango in 🔎-ricerca-partite per trovare giocatori del tuo livello."
                ></InfoCard></Col>
            </Row>
          </Container>
          <br />
          <iframe
            title="Discord widget"
            src="https://discord.com/widget?id=185687473337139200&amp;theme=dark"
            width="350"
            height="500"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </header>
      </div >
    );
  }
}

export default HomePage;
