import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import AlertDismissible from "../components/AlertDismissible";

class CreateTeamPage extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      logo_url: "",
      captain_1: "",
      captain_2: "",
      captain_3: "",
      platform: "PS4",
      game: "AOE2",
      error_message_title: "",
      error_message_variant: "",
      error_message_text: "",
      error_message_visible: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const body = {
      name: this.state.name,
      logo_url: this.state.logo_url,
      captain_1: this.state.captain_1,
      captain_2: this.state.captain_2,
      captain_3: this.state.captain_3,
      platform: this.state.platform,
      game: this.state.game,
    };
    let resultStatus = 0;
    //use access token to call endpoint localStorage.getItem('accessToken')
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/team/createTeam", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => {
        resultStatus = response.status;
        return response.json();
      })
      .then((jsonResult) => {
        console.log(resultStatus);
        switch (resultStatus) {
          case 200:
            console.log(jsonResult);
            break;
          case 400:
            if (jsonResult.error === "Bad Request") {
              let errors_list = jsonResult.errors.map((error, index) => {
                return (
                  <li
                    key={index}
                  >{`${error.field}: ${error.defaultMessage}`}</li>
                );
              });
              this.setState({
                error_message_variant: "danger",
                error_message_title: "Login Error!",
                error_message_text: errors_list,
                error_message_visible: true,
                isLoading: false,
              });
            } else {
              console.log(
                "this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)"
              );
            }
            break;
          case 401:
            this.setState({
              error_message_variant: "danger",
              error_message_title: "Login Error!",
              error_message_text: "Username or Password error",
              error_message_visible: true,
              isLoading: false,
            });
            break;
          case 500:
            this.setState({
              error_message_variant: "danger",
              error_message_title: "Login Error!",
              error_message_text: "Server error, try again!",
              error_message_visible: true,
              isLoading: false,
            });
            break;
          default:
            this.setState({
              error_message_variant: "danger",
              error_message_title: "Login Error!",
              error_message_text: "Server error, try again!!",
              error_message_visible: true,
              isLoading: false,
            });
            break;
        }
        this.setState({ isLoading: false });
      })
      .catch((error) =>
        this.setState({
          error_message_variant: "danger",
          error_message_title: "Login Error!",
          error_message_text: "Something went wrong",
          error_message_visible: true,
        })
      );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <div className="CreateTeamPage">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group as={Col} controlId="name">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Team Name
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="name"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="logo_url">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Logo url
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="logo_url"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="captain_1">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Captain
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="captain_1"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="captain_2">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Vice Captain
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="captain_2"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="captain_3">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Vice Captain
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="captain_3"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="platform">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Piattaforma
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control as="select" onChange={this.handleChange}>
                <option>PS4</option>
                <option>XBOX</option>
                <option>PC</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Col} controlId="game">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Gioco
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control as="select" onChange={this.handleChange}>
                <option>FIFA</option>
                <option>PES</option>
                <option>CTR</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <AlertDismissible
                error_message_text={this.state.error_message_text}
                error_message_visible={this.state.error_message_visible}
                error_message_title={this.state.error_message_title}
                error_message_variant={this.state.error_message_variant}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <Button type="submit" variant="outline-info">
                Register
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default CreateTeamPage;
