import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import AlertDismissible from "../components/AlertDismissible";

class ResetPage extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      error_message_text: "",
      error_message_visible: false,
    };
  }

  handleSubmit = (event) => {
    console.log(this.state);
    event.preventDefault();
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/user/resetPassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state),
    })
      .then((response) => {
        if (response.ok) {
          console.log(response.ok);
        } else {
          return response.json();
        }
      })
      .then((jsonResult) => {
        let errors_list = jsonResult.errors.map((error, index) => {
          return (
            <li key={index}>{`${error.field}: ${error.defaultMessage}`}</li>
          );
        });
        this.setState({
          error_message_variant: "danger",
          error_message_title: "Reset Error!",
          error_message_text: errors_list,
          error_message_visible: true,
        });
      })
      .catch((error) =>
        this.setState({
          error_message_variant: "danger",
          error_message_title: "Login Error!",
          error_message_text: "Something went wrong",
          error_message_visible: true,
        })
      );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <div className="ResetPage">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group as={Col} controlId="email">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Inserici la tua mail:
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="email"
                placeholder="email"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <AlertDismissible
                error_message_variant={this.state.error_message_variant}
                error_message_title={this.state.error_message_title}
                error_message_text={this.state.error_message_text}
                error_message_visible={this.state.error_message_visible}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <Button type="submit" variant="outline-info">
                Reset password
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default ResetPage;
