import React from "react";
import MaterialTable from "material-table";
import DiffArrow from "./DiffArrow";

class LeaderBoardSingleAoeItalia extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  apiUrl = "https://api.aoeitalia.com/v1/match/players?mode=1";

  refreshTable() {
    fetch(this.apiUrl)
      .then((response) => response.json())
      .then((data) => data.data)
      .then((players) =>
        this.setState({
          data: players.sort(function (a, b) { return parseInt(b.elo_1v1i) - parseInt(a.elo_1v1i) }),
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.refreshTable();
  }

  columns = [
    {
      title: "#",
      type: "numeric",
      sorting: false,
      render: (rowData, row) => {
        //return <div> {rowData.tableData.id+1}</div>
      },
    },
    { title: "Nome", field: "username" },
    {
      title: "Rating",
      field: "elo_1v1i",
      type: "numeric",
      defaultSort: "desc",
    },
    {
      title: "",
      type: "numeric",
      sorting: false,
      render: (rowData) => {
        var diff = rowData.elo_1v1i - rowData.prev_1v1i;
        if (rowData.prev_1v1i === 0) {
          return null;
        } else return <DiffArrow diff={diff} />;
      },
    },
    { title: "Max Rating", field: "top_1v1i", type: "numeric" },
    { title: "Ultima Striscia", field: "wir_1v1i", type: "numeric" },
    {
      title: "Giocate",
      field: "games_1v1i",
      type: "numeric",
      defaultFilter: "0",
      customFilterAndSearch: (term, rowData) =>
        Number(term) !== rowData.games_1v1i,
    },
    { title: "Vittorie", field: "wins_1v1i", type: "numeric" },
    {
      title: "Sconfitte",
      type: "numeric",
      render: (rowData) => {
        return rowData.games_1v1i - rowData.wins_1v1i;
      },
    },
    { title: "Ultima partita", field: "lastdt_1v1i" },
  ];

  render() {
    return (
      <div className="LeaderBoard">
        <MaterialTable
          columns={this.columns}
          data={this.state.data}
          title=""
          options={{
            search: false,
            fixedColumns: true,
            paging: true,
            pageSize: 25,
            emptyRowsWhenPaging: true,
            pageSizeOptions: [25, 50, 75, 100],
            maxBodyHeight: "900px",
            headerStyle: {
              backgroundColor: "#24a0ed",
              color: "white",
              padding: 5,
            },
            rowStyle: (data, index) => {
              if (index % 2) {
                return { backgroundColor: "#f8f8f8" };
              }
            },
            cellStyle: { padding: 8 },
          }}
          actions={[
            {
              icon: "refresh",
              isFreeAction: true,
              onClick: (event) => this.refreshTable(),
            },
          ]}
        />
      </div>
    );
  }
}

export default LeaderBoardSingleAoeItalia;
