import React from "react";

class WheelPage extends React.Component {
  render() {
    return (
      <div className="WheelPage">
        <header className="App-header">
          <h1>Ruota delle Civiltà</h1>
        </header>
        <header className="App-body">
          <iframe
            title="Aoe4 civ wheel"
            src="https://wheelofnames.com/bzg-p6x"
            width="100%"
            height="800"
          ></iframe>
          <br />
        </header>
      </div>
    );
  }
}

export default WheelPage;
