import React from "react";
import { Container } from "react-bootstrap";

class QualificazioniChampionsLeague extends React.Component {
  render() {
    return (
      <div className="QualificazioniChampionsLeague">
        <header className="App-header">
          <h1>Qualificazioni Champions League</h1>
          <h5>(Organizzato da Kani#6212)</h5>
        </header>
        <header className="App-body">
          <Container>
            <ol>
              <h2>
                <li>Descrizione</li>
              </h2>
              Le Qualificazioni Champions League sono 8 tornei 1vs1 a eliminazione diretta.
              <br />
              Gli 8 tornei sono indipendenti uno dall'altro. (potete iscrivervi a tutti, ad alcuni o ad uno solo)
              <br />
              Ogni torneo si svolgerà tutto in una serata. (3 partite al massimo)
              <br />
              Ogni torneo avrà una mappa su cui giocare tutte le partite (consultabile nella sezione Date)
              <br />
              In palio per ogni torneo ci sarà un posto per partecipare alla {' '}<a
                href="/tornei/ChampionsLeague"
                target="_blank"
                rel="noreferrer"
              >
                Champions League
              </a>
              {' '}(per un totale di 8 posti)
              <br />
              Quindi chi é già qualificato non potrà partecipare alle Qualificazioni Champions League.
              <br />
              <br />
              Numero di partecipanti: 8
              <br />
              Mappe: Lipany, Altai, Arabia Arida, Monti e valli, MegaRandom
              <br />
              Civiltà: Nessuna Restrizione
              <br />
              Draft civiltà:{' '}
              <a
                href="https://aoe2cm.net/preset/kZvdc"
                target="_blank"
                rel="noreferrer"
              >
                https://aoe2cm.net/preset/kZvdc
              </a>
              {' '}(Il draft viene usato solo per nascondere la civiltà all'avversario)
              <br />
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              Ad ognuno degli 8 giocatori viene assegnato un numero a seconda dei ranking points posseduti sul sito <a
                href="/classifiche/aoe4"
                target="_blank"
                rel="noreferrer"
              >
                https://aoeitalia.com/classifiche/aoe
              </a> (Classificata 1vs1).
              <br />
              Al giocatore con ranking points più alto viene assegnato il numero 1 e così via fino ad assegnare il numero 8 al giocatore con ranking points più basso.
              <br />
              Lo Staff potrà modificare i ranking points di un giocatore se questo non dovesse rispecchiare la realtà.
              <br />
              <br />
              <h2>
                <li>Formato Torneo</li>
              </h2>
              Verrà creato il tabellone delle eliminatorie nella seguente maniera:
              <ul>
                <li>1 - 5</li>
                <li>3 - 7</li>
                <li>2 - 6</li>
                <li>4 - 8</li>
              </ul>
              Le partite da disputare saranno: 4 quarti, 2 semifinali e 1 finale.
              <br />
              Sarà giocato un incontro diretto BO1 (solo andata) per tutte le fasi.
              <br />
              <br />
              <h2><li>Date</li></h2>
              <ul>
                <li>Mercoledi 27 Luglio (Lipany) - Vincitore Pagliu</li>
                <li>Domenica 31 Luglio (MegaRandom) - Vincitore ZetaVinz</li>
                <li>Mercoledi 3 Agosto (Altai) - Vincitore Mimmofalena</li>
                <li>Domenica 7 Agosto (Arabia Arida) - Vincitore Aleiltale</li>
                <li>Giovedi 18 Agosto (Monti e valli) - Vincitore Fuguee</li>
                <li>Domenica 21 Agosto (Lipany) - Vincitore Macromarco77</li>
                <li>Mercoledi 24 Agosto (Arabia Arida) - Vincitore GabriEL</li>
                <li>Domenica 28 Agosto (Altai) - Vincitore Marcotamby</li>
              </ul>
              <br />
              Orari:
              <ul>
                <li>ore 22:00 (+10min) si giocano i primi 4 quarti</li>
                <li>a seguire 2 semifinali</li>
                <li>a seguire la finale</li>
              </ul>
              <br />
              Tutte le partite verranno giocate in diretta con un delay di 3 minuti.
              <br />
              L'evento sarà castato sul canale{" "}
              <a
                href="https://www.twitch.tv/aoeitalia"
                target="_blank"
                rel="noreferrer"
              >
                https://www.twitch.tv/aoeitalia
              </a>
              <br />
              <br />
              <h2><li>Iscrizione</li></h2>
              Le iscrizioni termineranno quando si raggiunge il numero massimo di 8 partecipanti.
              <br />
              Per iscriversi è necessario entrare ed utilizzare il Server Discord <a
                href="/discord"
                target="_blank"
                rel="noreferrer"
              >
                Age of Empires Italia
              </a>
              <br />
              Registrarsi nel canale di testo "📢┃annunci" al torneo "Qualificazione Champions League"
              <br />
              <br />
              <h2><li>Lobby di gioco</li></h2>
              Per creare la lobby di gioco, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>MultiPlayer</li>
                <li>Personalizzata</li>
                <li>Crea Partita</li>
                <li>Reset</li>
                <li>Permetti spettatori: nessun ritardo</li>
                <li>Map setup</li>
                <li>Cambia Mappa</li>
                <li>Seleziona Mappa</li>
                <li>Indietro</li>
                <li>Map Size: Piccola (Max. 2 giocatori)</li>
                <li>Invita giocatore</li>
                <li>Aggiungi</li>
                <li>Scrivere il nome del proprio avversario</li>
                <li>Clicca Ricerca</li>
                <li>Selezionare l'icona invita nel gruppo a destra del nome del giocatore</li>
              </ol>
              <br />
              In caso di crash/disconnessione di un giocatore è consentito il restart fino al 5° minuto.
              <br />
              Se una squadra/giocatore non dovesse presentarsi all'incontro in orario, l'incontro viene assegnato come perso. (saranno concessi solamente 10 minuti di ritardo)
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default QualificazioniChampionsLeague;
