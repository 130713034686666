import React from "react";
import { Container, ListGroup } from "react-bootstrap";

class HallOfFame extends React.Component {
  render() {
    return (
      <div className="HallOfFame">
        <header className="App-header">
          <h1>Albo d'oro</h1>
        </header>
        <header className="App-body">
          <Container>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Cappa e Spada (2022)</h2>
                Codice Torneo: CeS-1
                <br />
                Age of Empires 4
                <br />
                Modalità: 1v1
                <br />
                <br />
                Vincitore Oro: Chrysaor
                <br />
                Vincitore Argento: <a
                  href="discord://discordapp.com/users/469859644035039254/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [LIVE] gattoratto
                </a>
                <br />
                Vincitore Bronzo: Pagliu
                <br />
                <a
                  href="https://challonge.com/tcs_fase1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Fase 1
                </a>
                <br />
                <a
                  href="https://challonge.com/tcs_oro"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Oro
                </a>
                <br />
                <a
                  href="https://challonge.com/tcs_argento"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Argento
                </a>
                <br />
                <a
                  href="https://challonge.com/tcs_bronzo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Bronzo
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>L'Ammucchiata del Gorattone (2022)</h2>
                Codice Torneo: ADG-1
                <br />
                Age of Empires 4
                <br />
                Modalità: FreeForAll
                <br />
                <br />
                Vincitore: [GG] Raffapry
                <br />
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Coppa degli Impazienti (2022)</h2>
                Codice Torneo: CDI-1
                <br />
                Age of Empires 4
                <br />
                Modalità: 2v2
                <br />
                <br />
                Vincitori: <a
                  href="discord://discordapp.com/users/524171603651592212/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [SPA] Gorat
                </a> e [SPA] Maverick
                <br />
                <a
                  href="https://challonge.com/it/5fy13bqc"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Italian Clan War (2022)</h2>
                Codice Torneo: ICW-1
                <br />
                Age of Empires 4
                <br />
                Modalità: 1v1, 2vs2, 3v3
                <br />
                <br />
                Vincitori: SPA 1
                <br />
                <a
                  href="https://challonge.com/it/bku7nesw"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo del Corvo (2022)</h2>
                Codice Torneo: TDC-1
                <br />
                Age of Empires 4
                <br />
                Modalità: 1v1
                <br />
                <br />
                Vincitore: <a
                  href="discord://discordapp.com/users/524171603651592212/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [SPA] Gorat
                </a>
                <br />
                <a
                  href="https://cdn.discordapp.com/attachments/938189041902436392/939933077231247360/aaaaaaaaaaaaaaaaaaaaaaaa.png"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo della Gloria 2 (2022)</h2>
                Codice Torneo: TDG-2
                <br />
                Age of Empires 4
                <br />
                Modalità: 2vs2
                <br />
                <br />
                Vincitori: <a
                  href="discord://discordapp.com/users/886225190575558677/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [ACS] CeccaZar
                </a> e <a
                  href="discord://discordapp.com/users/147418039929929728/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [ACS] Polenta
                </a>
                <br />
                <a
                  href="https://challonge.com/it/di1gsvga"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.twitch.tv/videos/1265589785"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Torneo
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo della Gloria (2021)</h2>
                Codice Torneo: TDG-1
                <br />
                Age of Empires 4
                <br />
                Modalità: 2vs2
                <br />
                <br />
                Vincitori: <a
                  href="discord://discordapp.com/users/886225190575558677/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [ACS] CeccaZar
                </a> e <a
                  href="discord://discordapp.com/users/147418039929929728/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [ACS] Polenta
                </a>
                <br />
                <a
                  href="https://challonge.com/it/l4kjm9p3"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.twitch.tv/videos/1231463683"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Torneo
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Community Invitational (2021)</h2>
                Codice Torneo: CI-1
                <br />
                Age of Empires 2: Definitive Edition
                <br />
                <br />
                Vincitore: <a
                  href="discord://discordapp.com/users/589517002113286145/"
                  target="_blank"
                  rel="noreferrer"
                >
                  [EVA] Lord Kelvin🦌
                </a>
                <br />
                <a
                  href="https://www.wesport.gg/play/knockouts/view/130/bracket"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.twitch.tv/videos/1205340231"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Eliminatorie
                </a>
                <br />
                <a
                  href="https://www.twitch.tv/videos/1205340854"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Finale
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Robin Hood Cup (2021)</h2>
                Codice Torneo: RHC-1
                <br />
                Age of Empires 2: Definitive Edition
                <br />
                Modalità: Clan Wars
                <br />
                <br />
                Vincitore Platino: Team Scarsenal
                <br />
                Membri: <a
                  href="discord://discordapp.com/users/217657103203041280/"
                  target="_blank"
                  rel="noreferrer"
                >
                  KainDB
                </a> e <a
                  href="discord://discordapp.com/users/218443613049192449/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Raziel
                </a>
                <br />
                <a
                  href="https://challonge.com/it/1tpi0j53"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Platino
                </a>
                <br />
                Vincitore Oro: Team Secret
                <br />
                Membri: <a
                  href="discord://discordapp.com/users/399236620173443072/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Grande GlopfGlopf
                </a>, <a
                  href="discord://discordapp.com/users/563329430538158111/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hangry
                </a>  e <a
                  href="discord://discordapp.com/users/541269520376135695/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tmouse
                </a>
                <br />
                <a
                  href="https://challonge.com/it/1p4w45az"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Oro
                </a>
                <br />
                Vincitore Argento: Clan GDR
                <br />
                Membri: <a
                  href="discord://discordapp.com/users/240938548763688960/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Salinaro
                </a>, <a
                  href="discord://discordapp.com/users/691000361208774747/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Nonno di heidi
                </a>, <a
                  href="discord://discordapp.com/users/706915542359080981/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Il Barone
                </a> e <a
                  href="discord://discordapp.com/users/687727210274357286/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PolPo
                </a>
                <br />
                <a
                  href="https://challonge.com/it/4mlk8fyl"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Argento
                </a>
                <br />
                Vincitore Bronzo: Clan MLT
                <br />
                Membri: <a
                  href="discord://discordapp.com/users/723897451303665704/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dawson07
                </a>, <a
                  href="discord://discordapp.com/users/714845667381477459/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Guillet Amhed
                </a> e <a
                  href="discord://discordapp.com/users/260780107927388170/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Belisario
                </a>
                <br />
                <a
                  href="https://challonge.com/it/kqk99l9f"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Bronzo
                </a>
                <br />
                Vincitore Legno: Clan EVA
                <br />
                Membri: <a
                  href="discord://discordapp.com/users/702646678125150268/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Principe
                </a> e <a
                  href="discord://discordapp.com/users/706240640798818446/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fiko
                </a>
                <br />
                <a
                  href="https://challonge.com/it/m7o7xxcn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets Legno
                </a>
                <br />
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Condottiero Cup (2021)</h2>
                Codice Torneo: CC-1
                <br />
                Age of Empires 2: Definitive Edition
                <br />
                Modalità: Battle Royal
                <br />
                <br />
                Vincitore: [OS] Carriage
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/1lk7taqzpaIdaXCIeALGJ_jsJ85iEoNuai8LUXf1vhP8/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo delle Signorie (2020)</h2>
                Codice Torneo: TDS-1
                <br />
                Age of Empires 2: Definitive Edition
                <br />
                Modalità: 4 vs 4, con squadre da 11 giocatori
                <br />
                <br />
                Vincitore: Ducato di Milano
                <br />
                - Capitano: [OS] Loris (aka Loris)
                <br />
                - Membri: Gio95 , Boom , Shinja, The Italian Lawyer , SwissYork
                , KiryL , rinsim , matt92tau , Macallan, agedroc
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/19uFyZ2xHogViCH_4_cl2nthI0NdKhQKxgsQaNqzrTjQ/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.twitch.tv/videos/849639879"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo Guerre Coloniali (2020)</h2>
                Codice Torneo: GC-1
                <br />
                Age of Empires 3: Definitive Edition
                <br />
                Modalità: 1 vs 1<br />
                <br />
                Vincitore: Blastkiller
                <br />
                <a
                  href="https://challonge.com/yy88h0e8"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo Italian Spring Cup (2020)</h2>
                Codice Torneo: ISC-1
                <br />
                Age of Empires 2: Definitive Edition
                <br />
                <br />
                Vincitore Lega Oro: Vodka
                <br />
                Vincitore Lega Argento: Lollo-01
                <br />
                Vincitore Lega Bronzo: Exagon
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/1MPzbQJUPGWUPt845aadbLr92veYQmE7xA-ZLrivq_t0/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.twitch.tv/videos/686351810"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Finale
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo King of Pizza (2019)</h2>
                Codice Torneo: KOP-1
                <br />
                Age of Empires 2: HD Edition
                <br />
                <br />
                Vincitore: YouGottaBKidding
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/1zQtdz6RN5kv0XpRWRHU_u2K18pP_V4U_30srBgQ4N-E/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.youtube.com/watch?v=qyT1Qk12iYU"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Finale
                </a>
                <br />
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Evento 2vs2 Team Island</h2>
                Age of Empires 2: HD Edition
                <br />
                Vincitore: The Favorits
                <br />
                Membri: Luca1997 e gaz di manos
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/1chOkjYqh9m6MHhXMIo7IRiCUMGtL6G1SytKeXikRobQ/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo Explorer Sheep Cup 2 (2018)</h2>
                Codice Torneo: ESC-2
                <br />
                Age of Empires 2: HD Edition
                <br />
                <br />
                Vincitore: J.D. Tommason
                <br />
                <a
                  href="https://challonge.com/it/lestoesc2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://youtu.be/YmJYRvmj7Cg"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
                <br />
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo Lesto Cup (2018)</h2>
                Codice Torneo: LC-1
                <br />
                Age of Empires 2: HD Edition
                <br />
                <br />
                Vincitore: Kamigawa
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/1LC22DdrdCPeMGsi1i4XX6kHDJB498AjVXy9FUfbGdk0/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://youtu.be/9KwRK1WRmdM"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo Feudalesimo e Libertà (2017)</h2>
                Codice Torneo: FEL-1
                <br />
                Age of Empires 2: HD Edition
                <br />
                <br />
                Vincitore: Rise
                <br />
                <a
                  href="https://docs.google.com/spreadsheets/d/1gAV1rtHY2aTuIU45sxcL7OsvhyNfTVHvHywMuQdNiI0/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.facebook.com/feudalesimoeliberta/videos/1064268807048722/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Torneo Explorer Sheep Cup (2017)</h2>
                Codice Torneo: ESC-1
                <br />
                Age of Empires 2: HD Edition
                <br />
                <br />
                Vincitore: endorama
                <br />
                <a
                  href="https://battlefy.com/59c1b3b008e897034d98dc9d/explorer-sheeps-cup/59c2614b51980b033c496edb/stage/59c264fd57bf80034196bb42/bracket/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brackets
                </a>
                <br />
                <a
                  href="https://www.youtube.com/watch?v=NA2Knzm1m-c"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </ListGroup.Item>
            </ListGroup>
            <br />
          </Container>
        </header>
      </div>
    );
  }
}

export default HallOfFame;
