import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
} from "@fortawesome/free-solid-svg-icons";

class DiffArrow extends React.Component {
  render() {
    if (Math.sign(this.props.diff) === 1) {
      return (
        <div>
          <FontAwesomeIcon icon={faLongArrowAltUp} color="#00ff00" />{' '}
          <small>{this.props.diff}</small>
        </div>
      );
    } else if (Math.sign(this.props.diff) === -1) {
      return (
        <div>
          <FontAwesomeIcon icon={faLongArrowAltDown} color="#ff0000" />{' '}
          <small>{this.props.diff}</small>
        </div>
      );
    }
    else {
      return (
        <div>
        </div>
      );
    }
  }
}

export default DiffArrow;