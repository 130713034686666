import React from "react";
import { Container } from "react-bootstrap";

class CalendarPage extends React.Component {
  render() {
    return (
      <div className="CalendarPage">
        <header className="App-header">
          <h1>Calendario AoeItalia.com</h1>
        </header>
        <header className="App-body">
          <Container>
            <iframe
              title="calendario aoeitalia.com"
              src="https://calendar.google.com/calendar/embed?src=age2italia%40gmail.com&ctz=Europe%2FRome"
              width="100%"
              height="600"
              frameborder="0"
            ></iframe>
          </Container>
          <br />
        </header>
      </div>
    );
  }
}

export default CalendarPage;
