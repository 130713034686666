import React from "react";
import { Container, Image } from "react-bootstrap";
import logo from "../../images/CW_logo.png";

class ClanWar extends React.Component {
  render() {
    return (
      <div className="ClanWar">
        <header className="App-header">
          <h1>Italian Clan War</h1>
          <h5>Age of Empires 4</h5>
        </header>
        <header className="App-body">
          <Container>
          <Image src={logo} alt="logo" fluid />
            <ol>
              <h2>
                <li>Descrizione</li>
              </h2>
              La Clan War è un torneo tra clan italiani di Age of Empires 4:
              <ul>
                <li>ACS</li>
                <li>CCP</li>
                <li>GG</li>
                <li>LIVE</li>
                <li>SPA</li>
              </ul>
              <br />
              La data ultima di iscrizione è giorno 30 Gennaio
              <br />
              I clan devono presentare l'iscrizione <a
                href="https://forms.gle/RA9tpF2PgMdA1w8W6"
                target="_blank"
                rel="noreferrer"
              >
                qua
              </a>.
              <br />
              Giocatori senza clan, che volessero partecipare devono iscriversi <a
                href="https://forms.gle/DCiJd8AVXK6tYso46"
                target="_blank"
                rel="noreferrer"
              >
                qua
              </a>.
              <br />
              Lo staff si occuperà di:
              <ul>
                <li>
                  Raggrupparli in un clan temporaneo chiamato "Mercenari".
                </li>
                <li>
                  Integrarli in clan già esistenti, se la prima opzione non
                  fosse possibile.
                </li>
              </ul>
              {/* <br />
              <a
                href="https://docs.google.com/"
                target="_blank"
                rel="noreferrer"
              >
                Rose Team
              </a>
              <br />
              <a
                href="https://challonge.com/it/m7o7xxcn"
                target="_blank"
                rel="noreferrer"
              >
                Calendario Mappe
              </a>
              <br />
              <a
                href="https://docs.google.com/"
                target="_blank"
                rel="noreferrer"
              >
                Formazioni Team
              </a> */}
              <br />
              <h2><li>Date</li></h2>
              Entro giorno 30 Gennaio deve essere consegnata la rosa degli 8 giocatori, con la formazione predefinita indicando il capitano del team.
              <br />
              <br />
              Prima giornata:
              <ul>
                <li>Le mappe della prima giornata verranno estratte giorno 30 Gennaio.</li>
                <li>Entro giorno 6 Febbraio deve essere consegnata la formazione della prima giornata.</li>
                <li>La prima giornata del torneo dovrà essere giocata nella settimana 7-13 Febbraio.</li>
              </ul>
              Seconda giornata:
              <ul>
                <li>Le mappe della seconda giornata verranno estratte giorno 6 Febbraio.</li>
                <li>Entro giorno 13 Febbraio deve essere consegnata la formazione della prima giornata.</li>
                <li>La prima giornata del torneo dovrà essere giocata nella settimana 14-20 Febbraio.</li>
              </ul>
              Terza giornata:
              <ul>
                <li>Le mappe della seconda giornata verranno estratte giorno 13 Febbraio.</li>
                <li>...</li>
                <li>...</li>
              </ul>
              e così via...
              <br />
              <br />
              Il torneo avrà quindi una durata di 7 settimane e concluderà nella settimana del 21-27 Marzo.
              <br />
              Le partite dovranno essere disputate nel giorno e orario predefinito: Domenica alle 21:00 (+15)
              <br />
              Il ritardo massimo sarà di 15 minuti.
              <br />
              E’ possibile mettersi d'accordo con il team avversario, per fissare date e orari alternativi.
              <br />
              Le partite devono essere giocate nella settimana corrente, quindi non è possibile organizzare partite in date e orari successivi a quella predefinita.
              <br />
              E’ possibile giocare le tre partite in giorni diversi.
              <br />
              Comunicate le eventuali nuove date e orari degli incontri delle partite nell'apposito canale testuale del Discord.
              <br />
              In caso di mancati accordi, la data delle partite sarà quella predefinita.
              <br />
              <br />
              <h2>
                <li>Formato Torneo</li>
              </h2>
              Un clan può presentare più di un team.
              <br />
              Il quale deve essere composto da 8 giocatori.
              <br />
              Lo staff organizza un girone unico all'italiana (detto anche round-robin) con tutti i team.
              <br />
              Questo prevede lo svolgimento di incontri diretti (solo andata) tra tutti i team in tutti gli abbinamenti possibili.
              <br />
              E' stato generato un calendario con diverse giornate:
              <br />
              <iframe src="https://challonge.com/it/bku7nesw/module?multiplier=0.9&match_width_multiplier=1.2&show_final_results=1&show_standings=1&show_tournament_name=1" title="Categoria Legno" width="100%" height="500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
              <br />
              Per ogni team, ogni giornata sono previste tre partite contro un altro team.
              <br />
              Le tre partite saranno:
              <ul>
                <li>un 1vs1</li>
                <li>un 2vs2</li>
                <li>un 3vs3</li>
              </ul>
              <br />
              Per ogni partita verranno assegnati dei punti:
              <ul>
                <li>team vincente: 1 punto</li>
                <li>team perdente: 0 punti</li>
              </ul>
              <br />
              Alla fine di tutte le giornate, il team con più punti sarà proclamato campione.
              <br />
              In caso di stesso numero di punti, verrà considerato lo scontro diretto.
              <br />
              <br />
              <h2><li>Mappe</li></h2>
              Bisogna assegnare tre mappe per ogni giornata esistente.
              <br />
              Ogni Domenica sera vengono estratte le tre mappe per la giornata successiva alla prossima.
              <br />
              L'assegnazione avviene attraverso un estrazione casuale di tutte le 17 mappe esistenti (escluse: Isole in guerra e Arcipelago).
              <br />
              Le tre partite di una stessa giornata non possono avere una mappa uguale.
              <br />
              <br />
              <h2><li>Formazioni</li></h2>
              Una settimana prima delle partite (Domenica entro le 23.59), ogni team deve presentare la formazione dei giocatori che scenderanno in campo la prossima giornata.
              <br />
              Se nessuna formazione viene inviata per tempo, verrà utilizzata l'ultima disponibile.
              <br />
              Una volta che la formazione é consegnata non sono consentiti cambi.
              <br />
              La formazione consegnata deve rispettare le seguenti regole:
              <ul>
                <li>Ogni giocatore può essere schierato solo una volta, in una singola modalità (1vs1, 2vs2, 3vs3).</li>
                <li>Solamente 6 degli 8 giocatori in rosa possono giocare le partite della giornata.</li>
                <li>Ad ogni giocatore dovrà essere associata una civiltà, che sarà diversa da quelle di tutti i suoi compagni di clan.</li>
              </ul>
              <br />
              Esempio di formazione valida:
              <ul>
                <li>1vs1: TheViper - Mongoli</li>
                <li>2vs2: Hera - Franchi</li>
                <li>2vs2: TheMista - Abbassidi</li>
                <li>3vs3: Vortix - Cinesi</li>
                <li>3vs3: Grubby - Delhi</li>
                <li>3vs3: Marinelord - Rus</li>
                <li>Non convocato: MaverickLotus</li>
                <li>Non convocato: KaniIta</li>
              </ul>
              <br />
              La consegna della formazione avverrà attraverso un link che verrò condiviso per ogni giornata.
              <br />
              Tutte le formazioni vengono rese pubbliche Domenica alle 23:59
              <br />
              <br />
              <h2><li>Lobby di gioco</li></h2>
              Per creare la lobby di gioco, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>MultiPlayer</li>
                <li>Personalizzata</li>
                <li>Crea Partita</li>
                <li>Reset</li>
                <li>Permetti spettatori: nessun ritardo</li>
                <li>Map setup</li>
                <li>Cambia Mappa</li>
                <li>Seleziona Mappa</li>
                <li>Indietro</li>
                <li>Map Size:
                  <ul>
                    <li>per 1vs1 seleziona: Micro (Max. 2 giocatori)</li>
                    <li>per 2vs2 seleziona: Small (Max. 4 giocatori)</li>
                    <li>per 3vs3 seleziona: Large (Max. 6 giocatori)</li>
                  </ul>
                </li>
                <li>Invita giocatore</li>
                <li>Aggiungi</li>
                <li>Scrivere il nome del proprio avversario</li>
                <li>Clicca Ricerca</li>
                <li>Selezionare l'icona invita nel gruppo a destra del nome del giocatore</li>
              </ol>
              <br />
              I giocatori devono entrare nella lobby secondo l'ordine della formazione consegnata.
              <br />
              <h2><li>Note Importanti</li></h2>
              In caso di crash/disconnessione di un giocatore è consentito il restart fino al 12° minuto.
              <br />
              Se una squadra/giocatore non dovesse presentarsi all'incontro, l'incontro viene assegnato come perso.
              <br />
              Per richiedere la vittoria a tavolino, le squadre che si presentano all'incontro dovranno presentare allo staff una cattura della schermata raffigurante orario, data e tutti i giocatori della propria squadra nel proprio canale vocale del <a
                href="https://discord.com/invite/pCUHbKM"
                target="_blank"
                rel="noreferrer"
              >
                server Discord di AoeItalia.com
              </a>
              <br />
              L'utilizzo perpetrato del bug della canceling animation causerà la sconfitta a tavolino.
              <br />
              Il risultato degli incontri delle partite va comunicato nell'apposito canale testuale del Discord.
              <br />
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default ClanWar;
