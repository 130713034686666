import React from "react";
import { Container, Image } from "react-bootstrap";

class BattleRoyal extends React.Component {
  render() {
    return (
      <div className="BattleRoyal">
        <header className="App-header">
          <h1>Battle Royale</h1>
        </header>
        <header className="App-body">
          <Container>
            <ol>
              <h2>
                <li>Note generali</li>
              </h2>
              Si tratta di una modalità pensata principalmente per il FFA (Free
              for All), quindi tutti contro tutti.
              <br />
              A differenza della modalità “Re della Collina” i giocatori NON
              potranno raccogliere risorse, creare abitanti del villaggio o
              costruire ulteriori edifici.
              <br />
              La durata di una partita è al massimo 15 minuti (30 minuti a
              velocità di gioco doppia). Potrebbe anche essere inferiore se
              venissero eliminati tutti gli avversari prima dello scadere del
              tempo.
              <br />
              Le risorse vengono generate in modo automatico.
              <br />
              Si parte con massima popolazione possibile (200) e nell’epoca
              post-imperiale (tutte le tecnologie quindi sono già ricercate)
              <br />
              <br />
              <h2>
                <li>Esplorazione</li>
              </h2>
              <Image
                src="https://lh6.googleusercontent.com/_fmB_TzPQQQB0kvjUF25t286GLb8v3y9YYGWr52064Sl5am1_tvOpwAeBUVoH_39YcQSxO_kA_3j9X-yEzMo-xOZUHarxcRQ9iAyY0iDmAtO9oqe9AURhM51tSac7e64AA"
                width="395"
                height="395"
                fluid
              />
              <br />
              <br />
              Ogni giocatore inizia la partita con un paio di unità eroiche.
              <br />
              Sfruttale al meglio per esplorare velocemente la mappa.
              <br />
              Perdere le unità eroiche NON porta alla sconfitta, tuttavia
              all’inizio sono estremamente preziose in quanto uniche unità
              militari a disposizione.
              <br />
              <br />
              <h2>
                <li>Conquista</li>
              </h2>
              <Image
                src="https://lh6.googleusercontent.com/QckEJuzaAlyUMtEx7icWbu_UlQ-90ewZ8o1bRNgi1mXA99BvNZp4m3hPdtcYXPDQJriLyBLHR7oZxSroMl9bRaddUEc5LWuLItNnydtyoBBuiefqDJ-IQg3hnR9oN_oyzw"
                width="421"
                height="421"
                fluid
              />
              <br />
              <br />
              Sconfiggi i guardiani neutrali e prendi il controllo degli
              avamposti militari sparsi per la mappa.
              <br />
              Per conquistare un avamposto è sufficiente avvicinarsi abbastanza
              ad esso, a patto che non ci siano unità militari nemiche nelle
              vicinanze.
              <br />
              Di conseguenza fai attenzione, se lasci un avamposto sguarnito
              anche una sola unità nemica può conquistarlo.
              <br />
              <br />
              <Image
                src="https://lh3.googleusercontent.com/f3sQeRbbnIio36or761_aBagiEqPTaS9eTZ-apcXcrnR62Ey-K6_ZK_uj2PTK6bX1b64q0R5dPrpBkw1SjSMUcF-vlktci5uD5JsZJbFm9zz5QiMTRnIo8LDO5wrQCtH7A"
                width="421"
                height="421"
                fluid
              />
              <br />
              <br />
              Il laboratorio commerciale è un edificio speciale che conferisce
              una generazione extra di risorse ed inoltre permette di bilanciare
              la propria economia in quanto funge anche da mercato.
              <br />
              <br />
              <h2>
                <li>Creazione dell’esercito</li>
              </h2>
              <Image
                src="https://lh6.googleusercontent.com/pJQPs1go0iRDJ0ODmCY8FPncsgVdSIKqufgxagoqqDa0H0-7ue1TlikP5ElYwqZLDkkrLtVYtBlpQ29qt_MrQOgDBSN4TG4JscAjKdji7z2NaPgOB2-RL-CHvvXl6hhpmQ"
                width="421"
                height="421"
                fluid
              />
              <br />
              <br />
              Recluta nuove truppe dagli edifici militari catturati. Spendi in
              modo accorto le risorse a tua disposizione in modo da formare un
              esercito in grado di fronteggiare qualsiasi situazione e minaccia.
              <br />
              Tieni conto dei punti di forza e debolezza della tua civiltà!
              <br />
              <br />
              <h2>
                <li>La nebbia Rossa</li>
              </h2>
              <Image
                src="https://lh3.googleusercontent.com/Iqa9LUYEAHkWr4SR3nNDbt-B6qB8flTbJ2v2nwLmwOTL9xL0HmWYuZIpnhqs-dwQzq3i7CBylpWFfXD6JTQ9_eRgSyc8qIK4r1Bu8OlrJF3Asz0bT1eOhH-IrPfGm7a7ZQ"
                width="421"
                height="421"
                fluid
              />
              <br />
              <br />
              Sia in partita che nella minimappa sarà presente una misteriosa
              nebbia rossa che lentamente ma inesorabilmente convergerà al
              centro distruggendo qualsiasi unità o edificio entri in suo
              contatto.
              <br />
              <br />
              <h2>
                <li>Il centro e classifica finale</li>
              </h2>
              Più tempo passerà e più la nebbia spingerà i giocatori a
              scontrarsi nella parte centrale della mappa. <br />
              Negli ultimi minuti ci sarà quindi la resa dei conti finale con
              gli ultimi giocatori rimasti in gara.
              <br />
              <br />
              <Image
                src="https://lh4.googleusercontent.com/EA9OaAN36-hqvyjn3SVFiprKdPR5DnJ4TbzG6XSvDFHN69RMkbDgSNAOjvIZmJwSLEFQj1OF-fJO6tEMicvs8aN0Uife-M4IQTjIvvJtNYqKGlJC_ScHLKC-oqmUu7aPzg"
                width="602"
                height="339"
                fluid
              />
              <br />
              <br />
              Altro scenario dove la nebbia rossa ricopre quasi tutta la mappa e
              l’ultima unità nemica è stata eliminata. Abbiamo un vincitore!
              <br />
              <br />
              <Image
                src="https://lh4.googleusercontent.com/s28WiODimQwDxLMkJRx6HvM9Bkw5KMWMWJwnUEFwU_ld44tuZN6RbQ1KauCr8qStAJIYkDuTehjmpx-b4cWVeKzB82S8llxlEbxLjzGpbEIvK0Daq2f4c8Czp0GYjuZ6WQ"
                width="602"
                height="339"
                fluid
              />
              <br />
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default BattleRoyal;
