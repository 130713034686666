import React from "react";
import { Container } from "react-bootstrap";


class CappaSpada extends React.Component {
  render() {
    return (
      <div className="CappaSpada">
        <header className="App-header">
          <h1>Cappa & Spada</h1>
          <h5>(Organizzato da EOG#4417)</h5>
          <h5>Age of Empires IV</h5>
        </header>
        <header className="App-body">
          <Container>
            <iframe
              src={"https://docs.google.com/document/d/e/2PACX-1vSZF-vXUOsZOW9NwQ-5CLzHlacgWiXPzuoV-9tmnnUW5ZCr0ZIA8HCT4bJb_tCHfur9ELl9TmbnPVUN/pub?embedded=true"}
              title="Cappa & Spada"
              width="100%"
              height="600"
            ></iframe>
          </Container>
        </header>
      </div>
    );
  }
}

export default CappaSpada;
