import React from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { LeaderBoardCompact, LeaderBoardRMCompact, LeaderBoardRMAoe4world } from "../components";

class LeaderBoardsPageAoe4 extends React.Component {
  render() {
    return (
      <div className="LeaderBoardsPageAoe4">
        <header className="App-header">
          <h1>Classifiche AoE4</h1>
          <h6>
            Per essere aggiunto alle classifiche, clicca il bottone "Iscriviti alle Classifiche" in alto a destra. Non usare Firefox.
          </h6>
        </header>
        <header className="App-body">
          <Container fluid>
            <Tabs defaultActiveKey="aoe4_rm_compact">
              <Tab eventKey="aoe4_rm_solo" title="Classificata Singolo">
                <LeaderBoardRMAoe4world leaderboardId="rm_solo" />
              </Tab>
              <Tab eventKey="aoe4_rm_team" title="Classificata Team">
                <LeaderBoardRMAoe4world leaderboardId="rm_team" />
              </Tab>
              <Tab eventKey="aoe4_rm_compact" title="Elo Classificate">
                <LeaderBoardRMCompact />
              </Tab>
              <Tab eventKey="aoe4_qm_compact" title="Elo Partite Rapide">
                <LeaderBoardCompact />
              </Tab>
            </Tabs>
          </Container>
        </header>
      </div>
    );
  }
}

export default LeaderBoardsPageAoe4;
