import React from "react";
import { Container } from "react-bootstrap";

class BestCoupleCup extends React.Component {
  render() {
    return (
      <div className="BestCoupleCup">
        <header className="App-header">
          <h1>Best Couple Cup</h1>
          <h5>(Idea di Acutetn#3622)</h5>
        </header>
        <header className="App-body">
          <Container>
            <ol>
              <h2>
                <li>Formato Torneo</li>
              </h2>
              <br />
              La Best Couple Cup è definita da 2 fasi.
              <br />
              <br />
              <h4>Prima Fase</h4>
              La prima fase è a girone all'italiana composta da 4 persone.
              <br />
              Ogni persona dovrà fare un 2v2 (allof3) con ognuno degli altri
              membri appartanenti al girone. <br />
              Ogni partita vale 1 punto. <br />I due giocatori che arriveranno
              primi nel girone faranno coppia fissa nelle fasi ad eliminazione.
              <br />
              In caso di pareggio fra giocatori il secondo e il terzo posto si
              farà uno spareggio con una BO1 1v1 Arabia Hidden pick. <br />
              <br />
              Civ Pick: Hidden Pick <br />
              Maps Pick : <br />
              <ul>
                <li>Game 1: HC Arabia</li>
                <li>Game 2: HC Arena</li>
                <li>Game 3: MegaRandom</li>
              </ul>
              <br />
              Esempio del Girone: <br />
              Giocatore 1 <br />
              Giocatore 2 <br />
              Giocatore 3<br />
              Giocatore 4 <br />
              <br />
              Turno 1 <br />
              Giocatore 1 e Giocatore 2 <br />
              vs <br />
              Giocatore 3 e Giocatore 4<br />
              <br />
              Turno 2<br />
              Giocatore 1 e Giocatore 3 <br />
              vs <br />
              Giocatore 2 e Giocatore 4<br />
              <br />
              Turno 3<br />
              Giocatore 1 e Giocatore 4 <br />
              vs <br />
              Giocatore 2 e Giocatore 3<br />
              <br />
              <h4>Seconda Fase</h4>
              Semifinali ad eliminazione diretta: BO5 <br />
              <br />
              Civ Pick: Draft con 2 ban e snipe
              <br />
              Maps Pick: Ban 2 Mappe
              <br />
              Map Pool:
              <ul>
                <li>Arena</li>

                <li>Quattro Laghi</li>
                <li>Black Forest</li>
                <li>HC Slopes</li>
                <li>HC Bay</li>
                <li>HC Cross</li>
                <li>HC Kawasan</li>
                <li>HC Mudflow</li>
                <li>HC Nascondiglio</li>
                <li>Forest Pond</li>
                <li>HC Bypass</li>
              </ul>
              <br />
              Il pick delle mappe prevede il seguente ordine: <br />
              <ul>
                <li>Game 1 Arabia.</li>
                <li>Game 2 mappa pickata dal team perdente del 1 game. </li>
                <li>
                  Game 3 Pick del vincente del game 1. Game 4 pick del vincente
                  del game 2.
                </li>
                <li>Game 5 pick del vincente del game 3. </li>
              </ul>
              <br />
              <h4>Fase Finale</h4>
              BO7
              <br />
              Civ Pick: Draft con 2 ban e 1 snipe. <br />
              Map Pick: come la Seconda Fase.
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default BestCoupleCup;
