import React from "react";
import { Container, Carousel, Image } from "react-bootstrap";
import crea_lobby from "../../images/RH_crea_lobby.png";
import impostazioni_lobby from "../../images/RH_impostazioni_lobby.png";
import locandina from "../../images/RH_locandina.jpg";

class RobinHoodCup extends React.Component {
  render() {
    return (
      <div className="RobinHoodCup">
        <header className="App-header">
          <h1>Robin Hood Cup</h1>
          <h5>(Organizzato da Kani#6212)</h5>
        </header>
        <header className="App-body">
          <Container>
            <ol>
              <Carousel>
              <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={locandina}
                    alt="Locandina"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <h3>Categoria Legno</h3>
                  </Carousel.Caption>
                  <iframe src="https://challonge.com/it/m7o7xxcn/module?multiplier=0.9&match_width_multiplier=1.2&show_final_results=1&show_standings=1&show_tournament_name=1" title="Categoria Legno" width="100%" height="750" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <h3>Categoria Bronzo</h3>
                    <p></p>
                  </Carousel.Caption>
                  <iframe src="https://challonge.com/it/kqk99l9f/module?multiplier=0.9&match_width_multiplier=1.2&show_final_results=1&show_standings=1&show_tournament_name=1" title="Categoria Bronzo" width="100%" height="750" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <h3>Categoria Argento</h3>
                    <p></p>
                  </Carousel.Caption>
                  <iframe src="https://challonge.com/it/4mlk8fyl/module?multiplier=0.9&match_width_multiplier=1.2&show_final_results=1&show_standings=1&show_tournament_name=1" title="Categoria Argento" width="100%" height="750" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <h3>Categoria Oro</h3>
                    <p></p>
                  </Carousel.Caption>
                  <iframe src="https://challonge.com/it/1p4w45az/module?multiplier=0.9&match_width_multiplier=1.2&show_final_results=1&show_standings=1&show_tournament_name=1" title="Categoria Oro" width="100%" height="750" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
                </Carousel.Item>
                <Carousel.Item>
                  <iframe src="https://challonge.com/it/1tpi0j53/module?multiplier=0.9&match_width_multiplier=1.2&show_final_results=1&show_standings=1&show_tournament_name=1" title="Categoria Platino" width="100%" height="500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
                  <Carousel.Caption>
                    <h3>Categoria Platino</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
              <br/>
              <h2>
                <li>Descrizione</li>
              </h2>
              La Robin Hood Cup e' un torneo che punta a far divertire i
              partecipanti, proponendo delle mappe discostanti dal meta.
              <br />
              Viene quindi favorito il gioco di squadra e l'utilizzo di tattiche
              innovative su mappe modificate dallo Staff.
              <br />
              Il torneo e' rivolto ai principalmente ai Clan e gruppi di
              Giocatori di varia dimensione.
              <br />
              Giocatori senza Clan, che volessero partecipare possono contattare
              lo Staff, che si occupera' di:
              <ul>
                <li>
                  Raggrupparli in un Clan temporaneo chiamato "Mercenari".
                </li>
                <li>
                  Integrarli a Clan gia' esistenti, se la prima opzione non
                  fosse possibile.
                </li>
              </ul>
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              Ogni Clan deve nominare un CapoClan, disponibile a collaborare con
              lo Staff.
              <br />
              Al fine di assistere ad incontri equilibrati, lo Staff, con
              l'aiuto dei Clan, stimera' un "Rating Reale" per ogni Giocatore
              partecipante:
              <ol>
                <li>
                  Verra' raccolta una lista di Giocatori per ogni Clan
                  partecipante.
                </li>
                <li>
                  Inizialmente, il "Rating Reale" viene definito privatamente
                  con i singoli Clan per ogni suo Giocatore.
                </li>
                <li>
                  Successivamente, vengono rese pubbliche tutte le stime di
                  "Rating Reale" a tutti i Clan.
                </li>
                <li>
                  I Clan quindi analizzano i "Rating Reale" dei propri
                  avversari.
                </li>
                <li>
                  I CapiClan hanno facolta' di richiedere un incremento di
                  "Rating Reale" per i Giocatori appartenti a Clan avversari.
                </li>
                <li>
                  Infine, tutti i Clan saranno daccordo sui "Rating Reale" di
                  ogni Giocatore
                </li>
              </ol>{" "}
              <br />
              <h2>
                <li>Formato Torneo</li>
              </h2>
              In seguito, lo Staff definira' delle Categorie, fissando degli
              intervalli di "Rating Reale" per ogni Categoria:
              <ol>
                <li>Categoria Legno ("Rating Reale" inferiore a 1000)</li>
                <li>Categoria Bronzo ("Rating Reale" tra 1001 e 1150)</li>
                <li>Categoria Argento ("Rating Reale" tra 1151 e 1300)</li>
                <li>Categoria Oro ("Rating Reale" tra 1301 e 1450)</li>
                <li>Categoria Platino ("Rating Reale" superiore a 1450)</li>
              </ol>
              I Giocatori verranno suddivisi in diverse categorie dallo Staff a
              seconda del loro "Rating Reale".
              <br />
              I Giocatori di un Clan, appartenti ad una singola Categoria,
              formeranno il Team per quella Categoria.
              <br />
              Qualora il numero di Giocatori per stesso Clan e Categoria sia
              abbastanza, possono essere formati due Team diversi di un Clan per
              una stessa Categoria.
              <br />
              Lo Staff organizza diversi gironi all'italiana, divisi per
              Categoria.
              <br />
              Per ogni girone, lo Staff pubblichera' un calendario.
              <br />
              Saranno previsti tre incontri diretti tra tutti i Team della
              stessa Categoria, in tutti gli abbinamenti possibili.
              <br />
              Il tipo di incontro (1vs1, 2vs2, 3vs3 o 4vs4) viene definito in
              base alla disponibilita' di Giocatori dei Clan per la Categoria
              corrispondente.
              <br />
              Ogni settimana sono previsti degli incontri ed è responsabilità
              dei Capitani e Team decidere giorno ed ora. Se al termine della
              settimana l'incontro non viene organizzato entrambi i Team vengono
              considerati perdenti.
              <br />
              Le mappe sono state decise dallo Staff:
              <ol>
                <li>Green Arabia con +1000 di oro come risorsa iniziale</li>
                <li>
                  Sherwood Forest con Robin Hood al posto del classico scout
                </li>
                <li>
                  Forest Pond in modalita Regicidio (con mura di pietra, torre
                  d'osservazione e scout)
                </li>
              </ol>
              La mod, disponibile direttamente nel gioco, contentente queste tre
              mappe é "AoeItalia - Robin Hood"
              <br />
              Tutte e tre le mappe vanno giocate.
              <br />
              Per ogni incontro verranno assegnati dei punti:
              <ol>
                <li>Team vincente: 1 punti</li>
                <li>Team perdente: 0 punto</li>
              </ol>
              In caso di crash o di bug della mappa è consentito il restore fino
              al 12° minuto. Se non è possibile in nessun modo il restore, si
              procede con un restart.
              <br />
              Il Team che si classifica al primo posto sarà proclamato campione
              della propria Categoria.
              <br />
              Il Clan con più Team campione di Categoria vincerà la Robin Hood
              Cup.
              <br />
              <br />
              <h2><li>Date</li></h2>
              <ul>
                <li>Le iscrizioni terminano il 16 Aprile alle 23.59.</li>
                <li>
                  Durante l'iscrizione viene fissato un "Rating Reale".
                  Normalmente é uguale al Rating 1v1 RM del sito aoe2.net. Nel
                  caso in cui non rispecchi la realtà è possibile cambiarlo.
                </li>
                <li>
                  Il 18 Aprile viene presentata la lista dei giocatori divisi
                  per "Rating Reale".
                </li>
                <li>
                  Dal 19 al 25 Aprile, vengono effettuati aggiustamenti dei
                  "Rating Reale" dei giocatori (se richiesto da altri
                  giocatori).
                </li>
                <li>
                  Il 25 Aprile alle 23.59 verrà pubblicata la lista delle
                  squadre divise per Categoria e i calendari dei vari gironi.
                </li>
                <li>Dal 8 Maggio inizieranno i primi scontri.</li>
                <li>
                  Le partite verranno castate su{" "}
                  <a
                    href="https://www.twitch.tv/aoeitalia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.twitch.tv/aoeitalia
                  </a>
                </li>
                <li>
                  Potete castare anche sul vostro canale inserendo nel titolo
                  (Hosted by AoeItalia.com)
                </li>
              </ul>
              L'iscrizione va effettuata su #✨-eventi-server:
              <ul>
                <li>
                  Mercenari: scrivete il link al vostro profilo (ad esempio{" "}
                  <a
                    href="https://aoe2.net/#profile-671883"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://aoe2.net/#profile-671883
                  </a>
                  ) e il "Rating Reale"
                </li>
                <li>
                  Clan: scrivete il nome del Clan seguito dalla lista di tutti
                  profili e il loro "Rating Reale"
                </li>
              </ul>
              La lista degli iscritti, dei loro "Rating Reale" e delle categorie
              sarà disponibile presso questo{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1I5nk42qMc40IrPq5e2A8b1ZSW71np3rXrCDb-YOUF0c/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                documento
              </a>
              <br />
              <br />
              <h2><li>Organizzazione della partita</li></h2>
              E' consigliabile nominare un Capitano per ogni Team, che avra' il
              compito di:
              <ul>
                <li>
                  Verificare la disponibilita' dei propri compagni di Team.
                </li>
                <li>
                  Decidere data e orario degli incontri insieme ai Capitani
                  avversari. (
                  <a
                    href="https://docs.google.com/spreadsheets/d/1I5nk42qMc40IrPq5e2A8b1ZSW71np3rXrCDb-YOUF0c/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lista partecipanti
                  </a>
                  )
                  <br />
                  Gli incontri vanno organizzati nella settimana indicata nei
                  seguenti calendari:
                  <ul>
                    <li>
                      <a
                        href="https://challonge.com/it/m7o7xxcn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Calendario - Categoria Legno
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://challonge.com/it/kqk99l9f"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Calendario - Categoria Bronzo
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://challonge.com/it/4mlk8fyl"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Calendario - Categoria Argento
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://challonge.com/it/1p4w45az"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Calendario - Categoria Oro
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://challonge.com/it/1tpi0j53"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Calendario - Categoria Platino
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Avvertire Streamers/Casters per streammare le partite sul
                  canale Twitch.
                </li>
                <li>Creare le stanze di gioco con i parametri corretti.</li>
                <li>Comunicare il risultato degli incontri allo Staff.</li>
              </ul>
              <br />
              <h3><li>Draft</li></h3>
              Le civiltà da utilizzare nelle partite vengono estratte attraverso
              un draft:
              <ol>
                <li>Decidere un orario per il draft con gli avversari.</li>
                <li>
                  Uno dei due Capitani crea il draft, cliccando uno dei due link
                  di sotto (a seconda del numero dei giocatori)
                  <ul>
                    <li>
                      2vs2:{" "}
                      <a
                        href="https://www.aoe2cm.net/preset/QTcNx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.aoe2cm.net/preset/QTcNx
                      </a>
                    </li>
                    <li>
                      3vs3:{" "}
                      <a
                        href="https://www.aoe2cm.net/preset/LyUWX"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.aoe2cm.net/preset/LyUWX
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Cliccare sul bottone: "Create new draft from this Preset"
                </li>
                <li>
                  Copiare il link sotto a "Send this url to the other captain:"
                  e inviarlo agli avversari
                </li>
                <li>
                  Copiare il link sotto a "Send this url to spectators:" e
                  inviarlo ai Streamers/Casters
                </li>
                <li>Scegliere il ruolo Host o Guest</li>
                <li>Cliccare su Ready</li>
                <li>Iniziare il draft</li>
              </ol>
              <br />
              <h3><li>Lobby di gioco</li></h3>
              Per creare la lobby di gioco, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>Selezionare l'ingranaggio in alto a destra</li>
                <li>Mod</li>
                <li>Scorri Mod</li>
                <li>Scrivere in Cerca: "AoeItalia - Robin Hood"</li>
                <li>Selezionare dalla lista: "AoeItalia - Robin Hood"</li>
                <li>Sottoscrivi</li>
                <li>Attendere che il download sia finito</li>
                <li>Indietro</li>
                <li>Più giocatori</li>
                <li>Ospita partita</li>
                <li>Visibilità: Pubblica</li>
                <li>Consenti spettatori: Si</li>
                <li>Nascondi civiltà: Si</li>
                <li>Ritardo spettatori: Nessuno</li>
                <li>Crea lobby</li>
                <Image src={crea_lobby} alt="Crea lobby" fluid />
                <li>
                  In alto a destra: Copia id partita e invialo ai
                  Streamers/Casters
                </li>
                <li>Reimposta</li>
                <li>Dimensione mappa: a seconda del numero dei giocatori</li>
                <li>Seleziona Posizione: Costa</li>
                <li>Stile mappa: Personalizzata</li>
                <li>Seleziona "Aoeitalia - RH - Nome Mappa"</li>
                <li>
                  Impostazioni Avanzate: attiva Modalità Regicidio (solo per
                  Forest Pond)
                </li>
                <Image src={impostazioni_lobby} alt="Impostazioni lobby" fluid />
              </ol>
              <br />
              <h3><li>Recupero Rec</li></h3>
              Per recuperare le registrazioni delle partite giocate, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>Un giocatore</li>
                <li>Carica partita</li>
                <li>Seleziona la scheda in alto a sinistra: Replay</li>
                <li>Ordina per data</li>
                <li>Individua la partita cliccando gli elementi della lista</li>
                <li>Annotarsi i nomi delle partite</li>
                <li>Clicca in basso a destra: Apri cartella partite salvate</li>
                <li>Copia i file con lo stesso nome e inviali ai Streamers/Casters via discord</li>
                <li>Ricordarsi di inviare anche il link del draft</li>
              </ol>
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default RobinHoodCup;
