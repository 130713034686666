import React from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { LeaderBoardSingleAoe2, LeaderBoardSingleAoeItalia, LeaderBoardTeamAoe2, LeaderBoardTeamAoeItalia } from "../components";

class LeaderBoardsPageAoe2 extends React.Component {
  render() {
    return (
      <div className="LeaderBoardsPageAoe2">
        <header className="App-header">
          <h1>Classifiche</h1>
        </header>
        <header className="App-body">
          <Container>
            <Tabs defaultActiveKey="aoe2_single">
              <Tab eventKey="aoe2_single" title="Classifica Singolo Aoe2.net">
                <LeaderBoardSingleAoe2 />
              </Tab>
              <Tab eventKey="aoe2_team" title="Classifica Squadre Aoe2.net">
                <LeaderBoardTeamAoe2 />
              </Tab>
              <Tab
                eventKey="aoeitalia_single"
                title="Classifica Singolo AoeItalia.com"
              >
                <LeaderBoardSingleAoeItalia />
              </Tab>
              <Tab
                eventKey="aoeitalia_team"
                title="Classifica Squadre AoeItalia.com"
              >
                <LeaderBoardTeamAoeItalia />
              </Tab>
            </Tabs>
          </Container>
        </header>
      </div>
    );
  }
}

export default LeaderBoardsPageAoe2;
