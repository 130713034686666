import React from "react";

class LicClanWarPage extends React.Component {
  render() {
    return (
      <div className="LicClanWarPage">
        <header className="App-header">
          <p>LIC Clan War di AoeItalia</p>
          <p>Ogni Clan deve nominare un CapoClan.</p>
          <p>
            Il CapoClan può presentare:
            <ol>
              <li>minimo di un Team</li>
              <li>massimo di 4 Team</li>
            </ol>
          </p>
          <p>
            Ogni Team può essere composto da:
            <ol>
              <li>minimo 4 giocatori</li>
              <li>massimo 8 giocatori (da decidere con i CapoClan)</li>
            </ol>
          </p>
          <p>
            I giocatori possono essere inseriti all'interno di un Team, se
            rispettano i seguenti requisiti:
            <ol>
              <li>Team A: elo 1400+ (da decidere con i CapoClan)</li>
              <li>Team B: elo 1200-1400 (da decidere con i CapoClan)</li>
              <li>Team C: elo 1000-1200 (da decidere con i CapoClan)</li>
              <li>Team D: elo 0-1000 (da decidere con i CapoClan)</li>
            </ol>
          </p>
          <p>
            All'interno di ogni Team deve essere nominato un capitano, che si
            occuperà di organizzare l'incontro con i Capitani dei Team
            avversari.
          </p>
          <p>
            Saranno organizzati quindi 4 gironi all'italiana (A,B C e D), divisi
            quindi per fascie di elo. Per ogni girone, verrà pubblicato un
            calendario e saranno previsti due incontri diretti (andata e
            ritorno) tra tutti i Team, in tutti gli abbinamenti possibili.
          </p>
          <p>
            <ol>
              <li>andata su mappa di terra (da decidere con i CapoClan)</li>
              <li>ritorno su mappa di mare (da decidere con i CapoClan)</li>
            </ol>
          </p>
          <p>
            Per ogni incontro verranno assegnati dei punti:
            <ol>
              <li>Team vincente sul campo - 3 punti</li>
              <li>Team perdente sul campo - 1 punto</li>
              <li>Team vincente a tavolino - 2 punti</li>
              <li>Team perdente a tavolino - 0 punti</li>
            </ol>
          </p>
          <p>
            Il Team che si aggiudica ogni incontro avrà il compito di scaricare
            la registrazione dal sito https://aoe2.net/
          </p>
          <p>
            I Team che si classicano al primo e secondo posto accedono alla
            finale. La finale si disputerà con formula al meglio dei tre game:
          </p>
          <p>
            <ol>
              <li>su mappa di terra (da decidere con i CapoClan)</li>
              <li>su mappa di mare (da decidere con i CapoClan)</li>
              <li>una terza a scelta dei Team (da decidere con i CapoClan)</li>
            </ol>
          </p>
          <p>
            Il Team che vincerà la finale sarà proclamato campione del proprio
            girone. Il Clan avente più Team come campione del girone sarà
            dichiarato vincitore della LIC Clan War. In caso di pareggio,
            verranno considerati in ordine di importanza:
            <ol>
              <li>Secondi posti di ogni girone</li>
              <li>Numero di punti per ogni girone</li>
              <li>Numero vittorie sul campo per ogni girone</li>
            </ol>
          </p>
          <p>
            In caso di crash o di bug della mappa è consentito il restore fino
            al 12° minuto. Se non è possibile in nessun modo il restore, si
            procede con un restart. Prima dell'inizio della Clan War sarà
            compito del CapoClan (e Capitani dei vari Team) far presente allo
            Staff qualsiasi possibilità che nei Team avversari siano presenti
            delle irregolarità:
            <ol>
              <li>elo non corretto o non vertiero dei giocatori</li>
              <li>giocatori non verificati</li>
            </ol>
          </p>
          <p>
            In caso di comprovata malafede, tutto il Clan (e tutti i suoi team)
            non potranno partecipare alla Clan War.
          </p>
        </header>
      </div>
    );
  }
}

export default LicClanWarPage;
