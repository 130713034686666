import React from "react";
import { Table, Container, Badge } from "react-bootstrap";

class RoleTable extends React.Component {
  render() {
    return (
      <div className="RoleTable">
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Rango</th>
                <th>Rating Min</th>
                <th>Rating Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Badge
                    className="badge"
                    bsPrefix="PecoraEsploratrice"
                    variant="primary"
                  >
                    Pecora Esploratrice
                  </Badge>
                </td>
                <td>0</td>
                <td>1000</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="Milizia"
                    variant="primary"
                  >
                    Milizia
                  </Badge></td>
                <td>1000</td>
                <td>1100</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="Arciere"
                    variant="primary"
                  >
                    Arciere
                  </Badge></td>
                <td>1100</td>
                <td>1200</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="Balestriere"
                    variant="primary"
                  >
                    Balestriere
                  </Badge></td>
                <td>1200</td>
                <td>1300</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="BalestriereScelto"
                    variant="primary"
                  >
                    Balestriere Scelto
                  </Badge></td>
                <td>1300</td>
                <td>1400</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="Campione"
                    variant="primary"
                  >
                    Campione
                  </Badge></td>
                <td>1400</td>
                <td>1500</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="Cavaliere"
                    variant="primary"
                  >
                    Cavaliere
                  </Badge></td>
                <td>1500</td>
                <td>1600</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="CavaliereScelto"
                    variant="primary"
                  >
                    Cavaliere Scelto
                  </Badge></td>
                <td>1600</td>
                <td>1700</td>
              </tr>
              <tr>
                <td><Badge
                    className="badge"
                    bsPrefix="Paladino"
                    variant="primary"
                  >
                    Paladino
                  </Badge></td>
                <td>1700</td>
                <td>*</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}

export default RoleTable;
