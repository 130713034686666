import React from "react";
import { Container, Image } from "react-bootstrap";
import logo from "../../images/CDN_logo.png";


class CoppaDelNonno extends React.Component {
  render() {
    return (
      <div className="CoppaDelNonno">
        <header className="App-header">
          <h1>Coppa Del Nonno</h1>
          <h5>Age of Empires 4</h5>
          <h5>(Organizzato da Kani#6212)</h5>
        </header>
        <header className="App-body">
          <Container>
            <Image src={logo} alt="logo" fluid center />
            <br />
            <br />
            {/* <iframe src="https://challonge.com/it/AoeItaliaChampionsLeague/module" title="Coppa Del Nonno - Fase a Gironi" width="100%" height="1500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe> */}
            <ol>
              <br />
              <br />
              <h2>
                <li>Descrizione</li>
              </h2>
              La Coppa Del Nonno è un torneo 1vs1 a cui possono partecipare giocatori con Ranked Matchmaking Elo minore di 1000.
              <br />
              Il torneo sarà diviso in due fasi:
              <ol>
                <li>Fase a Gironi: scontri diretti tra tutti i partecipanti di uno stesso girone.</li>
                <li>Fase Finale: semifinali ad eliminazione diretta in cui si qualificano i migliori di ogni girone.</li>
              </ol>
              <br />
              Numero massimo di partecipanti: da 8 a 12
              <br />
              Numero massimo di gironi: da 2 a 3
              <br />
              Numero massimo di partecipanti in un girone: 4
              <br />
              Map pool: Altai, Pinnacoli Antichi, Baia dei massi, Arabia Arida, Praterie, Lipany, Oasi, Paludi, Pozze d'acqua.
              <br />
              Civiltà: Nessuna Restrizione
              <br />
              Draft civiltà:{' '}
              <a
                href="https://aoe2cm.net/preset/kZvdc"
                target="_blank"
                rel="noreferrer"
              >
                https://aoe2cm.net/preset/kZvdc
              </a>
              {' '}(Il draft viene usato solo per nascondere la civiltà all'avversario)
              <br />
              Brackets:{' '}
              <a
                href="https://challonge.com/it/AoeItaliaCoppaDelNonno"
                target="_blank"
                rel="noreferrer"
              >
                https://challonge.com/it/AoeItaliaCoppaDelNonno
              </a>
              <br />
              <br />
              <h2><li>Date</li></h2>
              Domenica 26 Marzo 2023:
              <ul>
                <li>ore 23:59 - Chiusura iscrizioni</li>
              </ul>
              Lunedi 27 Marzo 2023:
              <ul>
                <li>ore 00:00 - Inizio torneo</li>
              </ul>
              Domenica 2 Aprile 2023:
              <ul>
                <li>ore 21:00 - Prima partita della Fase a Gironi</li>
                <li>ore 22:00 - Seconda partita della Fase a Gironi</li>
                <li>ore 23:00 - Terza partita della Fase a Gironi</li>
              </ul>
              Domenica 9 Aprile 2023:
              <ul>
                <li>ore 21:00 - le 2 Semifinali in contemporanea</li>
                <li>ore 22:00 - Finale (BO3)</li>
              </ul>
              <br />
              Il ritardo massimo per presentarsi alla partita sarà di 15 minuti.
              <br />
              E’ possibile mettersi d'accordo con il giocatore avversario, per fissare date e orari alternativi.
              <br />
              Le partite devono essere giocate nella settimana corrente, quindi non è possibile organizzare partite in date e orari successivi a quella predefinita.
              <br />
              E’ possibile giocare le tre partite in giorni diversi.
              <br />
              Comunicate le eventuali nuove date e orari degli incontri delle partite nell'apposito canale testuale del Discord.
              <br />
              In caso di mancati accordi, la data delle partite sarà quella predefinita.
              <br />
              <br />
              <h2>
                <li>Partecipanti</li>
              </h2>
              I giocatori che parteciperanno al torneo sono:
              <ul>
                <ol>
                  <li>Eca#3774</li>
                  <li>ReDeiMicci#8663</li>
                  <li>Hefe#6504</li>
                  <li>spyro#6071</li>
                  <li>Janko#8111</li>
                  <li>Lup3n#3213</li>
                  <li>xxalfredj90xx#7084</li>
                  <li>Seisler#1079</li>
                  <li>ℂ𝕒𝕚𝕞𝕒𝕟𝕠𝟡𝟟#0314</li>
                  <li>Temgio#2274</li>
                  <li>...</li>
                </ol>
              </ul>
              <br />
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              Ad ogni partecipante viene assegnato un numero a seconda dell'Elo Classificate (RM 1vs1) posseduto sul sito https://aoeitalia.com/classifiche/aoe4
              <br />
              Al partecipante con Elo più alto viene assegnato il numero 1 e così via fino ad assegnare il numero 9 al giocatore con Elo più basso.
              <br />
              Lo Staff potrà modificare l'Elo di un partecipante se questo non dovesse rispecchiare la realtà.
              <br />
              <br />
              <h2>
                <li>Fase a Gironi</li>
              </h2>
              Nella prima fase, i partecipanti saranno divisi in due-tre gironi, a seconda dell'Elo.
              {/* <ul>
                <li>
                  Girone A: partecipanti con Valutazione Rating uguale a 1,4,7,10
                </li>
                <li>
                  Girone B: partecipanti con Valutazione Rating uguale a 2,5,8,11
                </li>
                <li>
                  Girone C: partecipanti con Valutazione Rating uguale a 3,6,9,12
                </li>
              </ul> */}
              <br />
              Ogni partecipante dovrà quindi disputare in totale 3 partite, ovvero un incontro diretto BO1 (solo andata) per ogni avversario del proprio girone.
              <br />
              <br />
              La mappa verrà scelta dal Map Pool dal giocatore che possiede Elo più basso.
              <br />
              Per ogni partita verranno assegnati dei punti:
              <ul>
                <li>vincente: 1 punto</li>
                <li>perdente: 0 punti</li>
              </ul>
              <br />
              Alla fine di tutte le partite della fase a gironi, i partecipanti al primo posto e i migliori secondi si qualificheranno alla Fase Finale.
              <br />
              In caso di stesso numero di punti, verrà considerato lo scontro diretto.
              In caso di parità, verrà giocato uno spareggio FFA su Arabia Arida.
              <br />
              <br />
              Nota bene: Il numero di partecipanti per girone o il numero delle partite potrebbe variare a seconda del numero degli iscritti.
              <br />
              <br />
              <h2>
                <li>Fase Finale</li>
              </h2>
              Verrà creato il tabellone delle eliminatorie {/* nella seguente maniera:
              <ul>
                <li>giocatore Girone A - giocatore Girone C</li>
                <li>giocatore Girone B - giocatore Girone D</li>
              </ul> */}
              <br />
              Le partite da disputare saranno: 2 semifinali e 1 finale.
              <br />
              <br />
              Per la semifinale:
              <ul>
                <li>Sarà giocato un incontro diretto BO1 (solo andata)</li>
                <li>La mappa verrà scelta dal Map Pool dal giocatore che possiede Elo più basso.</li>
              </ul>
              <br />
              Per la finale:
              <ul>
                <li>Sarà giocato BO3 (al meglio di 3 partite)</li>
                <li>La mappa del primo incontro verrà scelta dal giocatore che possiede Elo più basso.</li>
                <li>La mappa del secondo incontro sarà scelta dal perdente del primo incontro (Mappa all'interno del pool, mappa del primo incontro esclusa).</li>
                <li>Qualora si dovesse tenere, la mappa del terzo incontro sarà scelta dal perdente del secondo incontro (Mappa all'interno del pool, mappa del primo e secondo incontro escluse).</li>
              </ul>
              <br />
              <h2><li>Lobby di gioco</li></h2>
              Per creare la lobby di gioco, dal menu principale seguire i seguenti passi:
              <ol>
                <li>MultiPlayer</li>
                <li>Personalizzata</li>
                <li>Crea Partita</li>
                <li>Reset</li>
                <li>Permetti spettatori: nessun ritardo</li>
                <li>Map setup</li>
                <li>Cambia Mappa</li>
                <li>Seleziona Mappa</li>
                <li>Indietro</li>
                <li>Map Size: Piccola (Max. 2 giocatori)</li>
                <li>Invita giocatore</li>
                <li>Aggiungi</li>
                <li>Scrivere il nome del proprio avversario</li>
                <li>Clicca Ricerca</li>
                <li>Selezionare l'icona invita nel gruppo a destra del nome del giocatore</li>
              </ol>
              <br />
              In caso di crash/disconnessione di un giocatore è consentito il restart fino al 5° minuto.
              <br />
              Se un giocatore non dovesse presentarsi all'incontro in orario, l'incontro viene assegnato come perso.
              <br />
              <br />
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default CoppaDelNonno;
