import React from "react";
import {
  Nav, Navbar, NavDropdown, Button, Container
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../logo.png";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: undefined
    };
  }

  getUsername() {
    fetch(process.env.REACT_APP_BACKEND_HOST + "/api/user/username", { method: 'GET', credentials: 'include' })
      .then((response) => response.text())
      .then((name) => this.setState({
        username: name
      }))
      .catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.getUsername();
  }

  render() {
    const { username } = this.state;

    return (
      <div className="TopBar">
        <Helmet>
          <html lang="it" />
          <title>{process.env.REACT_APP_WEBSITE_NAME}</title>
          <meta name="description" content={process.env.REACT_APP_WEBSITE_NAME + " (Aoeitalia.com) è la più grande community online italiana discord di Age Of Empires 4 ed Age Of Empires 2 DE!\n Da noi troverai un ambiente tranquillo dove giocare e divertirti con altri italiani. Potrai approfondire il lato competitivo del gioco, partecipare a tornei o unirti ad un clan italiano di AoE2 o Aoe4."} />
        </Helmet>
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                alt=""
                src={logo}
                width="40"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              {process.env.REACT_APP_WEBSITE_NAME}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Classifiche" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/classifiche/aoe2">
                    Age of Empires 2 DE
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/classifiche/aoe4">
                    Age of Empires IV
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Università" id="basic-nav-dropdown">
                  <NavDropdown title="Age of Empires 2 DE" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/universita/mods">
                      Guide e Mods
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item href="/">
                      Lezioni
                    </NavDropdown.Item> */}
                    <NavDropdown.Item href="/universita/battleRoyal">
                      Battle Royal
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item href="/">
                      Link Utili
                    </NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown title="Age of Empires IV">
                    <NavDropdown.Item href="/universita/aoe4">
                      Guide
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>
                <LinkContainer to="/clan">
                  <Nav.Link>Clan</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Tornei" id="basic-nav-dropdown">
                  <NavDropdown title="Age of Empires 2 DE" id="basic-nav-dropdown">
                    <NavDropdown.Header>Futuri</NavDropdown.Header>
                    <NavDropdown.Item href="/tornei/coopCup">
                      Coop Cup
                    </NavDropdown.Item>
                    <NavDropdown.Header>Terminati</NavDropdown.Header>
                    <NavDropdown.Item href="/tornei/communityInvitational">
                      Community Invitational
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/robinHoodCup">
                      Robin Hood Cup
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/condottieroCup">
                      Condottiero Cup
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Age of Empires IV">
                    <NavDropdown.Header>Futuri</NavDropdown.Header>
                    <NavDropdown.Item href="/tornei/DeathTournamentWFD">
                      1v1 Death Tournament WFD
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/coppaDelNonno">
                      Coppa Del Nonno
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/torneoDei3Campioni">
                      Torneo dei 3 Campioni
                    </NavDropdown.Item>
                    <NavDropdown.Header>Terminati</NavDropdown.Header>
                    <NavDropdown.Item href="/tornei/championsLeague">
                      Champions League
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/qualificazioniChampionsLeague">
                      Qualificazioni Champions League
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      Pentathlon
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/memorialCCP">
                      Memorial CCP
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/tcs">
                      Cappa e Spada
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/clanWar">
                      Italian Clan War
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/tdg2">
                      Torneo della Gloria 2
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/tornei/tdg">
                      Torneo della Gloria
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/tornei/hallOfFame">
                    Albo d'oro
                  </NavDropdown.Item>
                </NavDropdown>
                <LinkContainer to="/social">
                  <Nav.Link>Social Network</Nav.Link>
                </LinkContainer>
              </Nav>

              {username ? (<Nav className="mr-right">
                <NavDropdown title={username} id="basic-nav-dropdown" alignRight>
                  <NavDropdown.Item href="/profile">
                    Il tuo profilo
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={process.env.REACT_APP_BACKEND_HOST + "/logout"} >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              ) : (<Nav className="mr-right">
                <Nav.Link href={process.env.REACT_APP_BACKEND_HOST + "/oauth2/authorization/discord"}>
                  <Button variant="outline-primary">
                    <FontAwesomeIcon
                      icon={faDiscord}
                      color="#7289d9"
                      className="mr-1"
                    />
                    Iscriviti alle Classifiche
                  </Button>
                </Nav.Link>
              </Nav>
              )}

              {/* <Nav className="mr-right">
                    <NavDropdown title="Accedi/Iscriviti" id="basic-nav-dropdown">
                        <LinkContainer to="/login">
                            <NavDropdown.Item href="#action/3.1">Log in</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/signup">
                            <NavDropdown.Item href="#action/3.2">Register</NavDropdown.Item>
                        </LinkContainer>
                    </NavDropdown>
                </Nav> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div >
    );
  }
}

export default TopBar;
