import React from "react";
import MaterialTable from "material-table";
import DiffArrow from "./DiffArrow";
import RankLevelIcon from "./RankLevelIcon";
import StreakIcon from "./StreakIcon";

class LeaderBoardRMAoe4world extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  apiUrl = process.env.REACT_APP_BACKEND_HOST + `/api/getAoe4worldPlayers?leaderboard=${this.props.leaderboardId}`;

  refreshTable() {
    fetch(this.apiUrl)
      .then((response) => response.json())
      .then((players) =>
        this.setState({
          data: players.sort(function (a, b) { return parseInt(b.rating) - parseInt(a.rating) }),
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.refreshTable();
  }

  columns = [
    {
      title: "#",
      type: "numeric",
      sorting: false,
      render: (rowData, row) => {
        return <div> {rowData.tableData.id + 1}</div>
        //console.log(rowData.lookup);
      },
    },
    {
      field: 'discord_avatar',
      title: '',
      sorting: false,
      grouping: false,
      align: "right",
      render: rowData =>
        rowData.discord_avatar ?
          <img src={`https://cdn.discordapp.com/avatars/${rowData.discord_id}/${rowData.discord_avatar}.webp?size=1024`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://cdn.discordapp.com/embed/avatars/1.png?size=1024";
            }
            }
            alt="avatar"
            style={{ width: 25, borderRadius: '50%' }}
          /> :
          <img src={`https://cdn.discordapp.com/embed/avatars/0.png?size=1024`}
            alt="avatar"
            style={{ width: 25, borderRadius: '50%' }}
          />
    },
    {
      title: "Utente Discord", field: "discord_name", type: "numeric", align: "left",
      render: rowData => <a
        href={`discord://discordapp.com/users/${rowData.discord_id}/`}
        target="_blank"
        rel="noreferrer"
      >
        {rowData.discord_name}
      </a>
    },
    {
      title: "Utente AoE", field: "name",
      render: rowData => <a
        href={`${rowData.site_url}`}
        target="_blank"
        rel="noreferrer"
      >
        {rowData.name}
      </a>
    },
    {
      title: "Rank", field: "rank_level", align: "center",
      sorting: false,
      grouping: false, render: (rowData) => {
        return <RankLevelIcon rank_level={rowData.rank_level} />;
      },
    },
    { title: "Ranking Points", field: "rating", type: "numeric", defaultSort: "desc", align: "center" },
    {
      title: "",
      type: "numeric",
      sorting: false,
      align: "left",
      render: (rowData) => {
        return <DiffArrow diff={rowData.last_rating_change} />;
      },
    },
    { title: "Max RP", field: "highest_rating", type: "numeric" },
    { title: "Partite", field: "games", type: "numeric" },
    {
      title: "Ultima Serie", field: "streak", type: "numeric", align: "center", render: (rowData) => {
        return <StreakIcon diff={rowData.streak} />;
      }
    },
    { title: "Vinte", field: "wins", type: "numeric" },
    { title: "Perse", field: "losses", type: "numeric" },
    { title: "Disconnesse", field: "drops", type: "numeric" },
    {
      title: "Win%",
      type: "numeric",
      sorting: false,
      field: "win_rate",
      render: (rowData) => {
        return rowData.win_rate + "%";
      },
    },
    {
      title: "Ultima partita", field: "last_match_time",
      render: (rowData) => {
        var myDate = new Date(rowData.last_match_time);
        return (<div>{myDate.toLocaleString()}</div>);
      },
    },
  ];

  render() {
    return (
      <div className="LeaderBoardAoe4world">
        <MaterialTable
          columns={this.columns}
          data={this.state.data}
          title=""
          options={{
            actionsColumnIndex: -1,
            fixedColumns: true,
            paging: true,
            pageSize: 75,
            emptyRowsWhenPaging: true,
            pageSizeOptions: [25, 50, 75, 100],
            maxBodyHeight: "900px",
            headerStyle: {
              backgroundColor: "#24a0ed",
              color: "white",
              padding: 5,
            },
            rowStyle: (data, index) => {
              if (index % 2) {
                return { backgroundColor: "#f8f8f8" };
              }
            },
            cellStyle: { padding: 8 },
          }}
          actions={[
            {
              icon: "refresh",
              isFreeAction: true,
              onClick: (event) => this.refreshTable(),
            },
          ]}
        />
      </div>
    );
  }
}

export default LeaderBoardRMAoe4world;
