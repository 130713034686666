export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_PENDING = 'LOGIN_PENDING';

export const loginPending = () => {
    return{
        type: LOGIN_PENDING
    };
};

export const loginSuccess = () => {
    return{
        type: LOGIN_SUCCESS
    };
};

export const loginError = (errors_list) => {
    return{
        type: LOGIN_ERROR,
        payload: errors_list
    };
};