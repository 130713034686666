import React from "react";
import { Container, Image } from "react-bootstrap";
import logo from "../../images/Memorial_CCP.jpg";



class MemorialCCP extends React.Component {
  render() {
    return (
      <div className="MemorialCCP">
        <header className="App-header">
          <h1>Memorial CCP</h1>
          <h5>Age of Empires 4</h5>
          <h5>(Organizzato da Kani#6212)</h5>
        </header>
        <header className="App-body">
          <Container>
            <Image src={logo} alt="logo" fluid />
            <ol>
              <br />
              <br />
              <h2>
                <li>Descrizione</li>
              </h2>
              Il Memorial CCP è un torneo 3vs3 diviso in due fasi:
              <ol>
                <li>Fase a Gironi: scontri diretti tra tutti i team di uno stesso girone.</li>
                <li>Fase Finale: semifinali ad eliminazione diretta in cui si qualifica un team per ogni girone.</li>
              </ol>
              <br />
              Numero massimo di team: 16
              <br />
              Numero massimo di gironi: 4
              <br />
              Numero massimo di team in un girone: 4
              <br />
              Map pool: Arabia Arida, Mega-Random, Nomade
              <br />
              Civiltà: Nessuna Restrizione
              <br />
              <br />
              L'idea del torneo è quella di ricordare l'altisonante Clan CCP (estinti a fine Aprile 2022), quindi sarà più facile partecipare al torneo con un ex-CCP in squadra o con giocatori senza clan.
              <br />
              In questo torneo tutti gli altri Clan hanno meno importanza, quindi non esisteranno team formati da membri di un solo clan.
              <br />
              Verrà favorita la coesione e cooperazione della community, dunque i team saranno formati da membri appartenenti a clan diversi.
              <br />
              Avere troppi ex-CCP in team causerà uno svantaggio organizzativo e potrebbe favorire gli avversari nella scelta della mappa.
              <br />
              Come da tradizione, l'ex CapoClan dei CCP non gioca team games, piuttosto, se vorrà, gli sarà affidato l'onere di castare le partite.
              <br />
              <br />
              <h2><li>Date</li></h2>
              Domenica 10 Luglio 2022:
              <ul>
                <li>ore 15:00 - Prima partita della Fase a Gironi</li>
                <li>ore 16:30 - Seconda partita della Fase a Gironi</li>
                <li>ore 18:00 - Terza partita della Fase a Gironi</li>
              </ul>
              <br />
              Domenica 17 Luglio 2022:
              <ul>
                <li>ore 15:00 - le 2 Semifinali in contemporanea</li>
                <li>ore 16:30 - Finale (BO3)</li>
              </ul>
              <br />
              Il ritardo massimo sarà di 15 minuti.
              <br />
              E’ possibile mettersi d'accordo con il team avversario, per fissare date e orari alternativi.
              <br />
              Le partite devono essere giocate nella settimana corrente, quindi non è possibile organizzare partite in date e orari successivi a quella predefinita.
              <br />
              E’ possibile giocare le tre partite in giorni diversi.
              <br />
              Comunicate le eventuali nuove date e orari degli incontri delle partite nell'apposito canale testuale del Discord.
              <br />
              In caso di mancati accordi, la data delle partite sarà quella predefinita.
              <br />
              <br />
              <h2>
                <li>Iscrizione</li>
              </h2>
              Le iscrizioni termineranno quando si raggiunge il numero massimo di partecipanti oppure Domenica 3 Luglio 2022 alle 21:00.
              <br />
              E' possibile:
              <ul>
                <li>Iscriversi singolarmente: in questo caso lo Staff si occuperà di assegnarvi ad un team.</li>
                <li>Iscriversi in team: vi occuperete voi di trovare i membri del vostro team.</li>
              </ul>
              <br />
              <a
                href="https://forms.gle/2nV2aZZJyjy2F6xs7"
                target="_blank"
                rel="noreferrer"
              >
                Clicca qua per iscriverti!
              </a>
              <br />
              <br />
              I team devono rispettare le seguenti regole:
              <ul>
                <li>
                  Minimo 3 membri (devono essere presenti per tutta la durata del torneo, pena la sconfitta a tavolino).
                </li>
                <li>
                  Massimo 4 membri (possono ruotare senza nessuna restrizione, per garantire più facilmente la presenza alle partite).
                </li>
                <li>
                  Tutti i membri di un team devono appartenere a clan differenti, amenochè non siano ex-CCP.
                </li>
                <li>
                  Giocatori senza clan con Max Rating (Ranked 1vs1) minore di 1000 vengono considerati come degli ex-CCP.
                </li>
                <li>
                  Giocatori senza clan con Max Rating (Ranked 1vs1) maggiore di 1000 vengono considerati come parte del Clan temporaneo ANTICCP (quindi due o più ANTICCP non possono giocare nello stesso team).
                </li>
              </ul>
              <br />
              Esempi di team validi:
              <ul>
                <li>
                  Topolino(Nessun Clan), Paperino(Nessun Clan), Pippo(Nessun Clan), Paperoga(Nessun Clan)
                </li>
                <li>
                  Kani_85(SPA), CeccaZar(GDA), Daddu235844(LIVE), Raffapry(GG)
                </li>
                <li>
                  Kani_85(SPA), CeccaZar(GDA), Daddu235844(LIVE), YoshimitsuM27(ex-CCP)
                </li>
                <li>
                  Kani_85(SPA), CeccaZar(GDA), EniGmA(ex-CCP), YoshimitsuM27(ex-CCP)
                </li>
                <li>
                  Kani_85(SPA), Drifo(ex-CCP), EniGmA(ex-CCP), YoshimitsuM27(ex-CCP)
                </li>
                <li>
                  Sbreda95(ex-CCP), Drifo(ex-CCP), EniGmA(ex-CCP), YoshimitsuM27(ex-CCP)
                </li>
              </ul>
              <br />
              Esempi di team NON validi:
              <ul>
                <li>
                  Kani_85(SPA), Gorat(SPA), Daddu235844(LIVE), Raffapry(GG)
                </li>
                <li>
                  Kani_85(SPA), Gorat(SPA), Daddu235844(LIVE), EniGmA(ex-CCP)
                </li>
                <li>
                  Kani_85(SPA), Gorat(SPA), V1RuS-eXpOrT(SPA), Raffapry(GG)
                </li>
                <li>
                  Kani_85(SPA), Gorat(SPA), V1RuS-eXpOrT(SPA), EniGmA(ex-CCP)
                </li>
                <li>
                  Kani_85(SPA), Gorat(SPA), V1RuS-eXpOrT(SPA), Naitro(SPA)
                </li>
              </ul>
              <br />
              Lista ex-CCP:
              <ul>
                <li>[LIVE] Bad J4nk0</li>
                <li>[LIVE] David</li>
                <li>[LIVE] Nico46</li>
                <li>[LIVE] Phaumiel</li>
                <li>[LIVE] Specialone-886</li>
                <li>[LIVE] TheQuietMan_7</li>
                <li>[LIVE] WAR ZEM 26</li>
                <li>[LIVE] YoshimitsuM27</li>
                <li>[LIVE]Tonibi</li>
                <li>[LivER] EniGmA</li>
                <li>[SPA] Drifo</li>
                <li>[SPA] Sbreda</li>
                <li>[GDA] Arkhan</li>
                <li>[GDA] Aleiltale || Twitch</li>
                <li>[GDA] Damage95</li>
                <li>[GDA] Lesbolas</li>
                <li>[GDA] RoseScissor</li>
                <li>[GDA] g1</li>
                <li>GP25.SevisyanTTV</li>
              </ul>
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              Ad ogni team viene assegnato un numero a seconda della somma dei Max Rating (Partita Rapida 3v3) posseduti dai membri del team sul sito https://aoeitalia.com/classifiche/aoe4
              <br />
              Al team con somma Max Rating più alto viene assegnato il numero 1 e così via fino ad assegnare il numero 16 al team con somma Max Rating più basso.
              <br />
              Lo Staff potrà modificare il Max Rating di un giocatore se questo non dovesse rispecchiare la realtà.
              <br />
              <br />
              <h2>
                <li>Fase a Gironi</li>
              </h2>
              Nella prima fase, i team saranno divisi in quattro gironi, a seconda della Valutazione Rating.
              <ul>
                <li>
                  Girone A: team con Valutazione Rating uguale a 1,5,9,13
                </li>
                <li>
                  Girone B: team con Valutazione Rating uguale a 2,6,10,14
                </li>
                <li>
                  Girone C: team con Valutazione Rating uguale a 3,7,11,15
                </li>
                <li>
                  Girone D: team con Valutazione Rating uguale a 4,8,12,16
                </li>
              </ul>
              <br />
              Ogni team dovrà quindi disputare in totale 3 partite, ovvero un incontro diretto BO1 (solo andata) per ogni avversario del proprio girone.
              <br />
              <br />
              La mappa verrà scelta dal Map Pool dal team che possiede meno ex-CCP in rosa.
              <br />
              In caso di stesso numero di ex-CCP, ci si potrà mettere daccordo sulla scelta della mappa, se non é possibile si giocherà su Mega-Random.
              <br />
              Per ogni partita verranno assegnati dei punti:
              <ul>
                <li>team vincente: 1 punto</li>
                <li>team perdente: 0 punti</li>
              </ul>
              <br />
              Alla fine di tutte le partite:
              <ul>
                <li>I teams al primo posto si qualificheranno alla Fase Finale Platino.</li>
                <li>I teams al secondo posto si qualificheranno alla Fase Finale Oro.</li>
                <li>I teams al terzo posto si qualificheranno alla Fase Finale Argento.</li>
                <li>I teams al quarto posto si qualificheranno alla Fase Finale Bronzo.</li>
              </ul>
              <br />
              In caso di stesso numero di punti, verrà considerato lo scontro diretto.
              <br />
              <br />
              Nota bene: Il numero di team per girone o il numero delle partite potrebbe variare a seconda del numero degli iscritti.
              <br />
              <br />
              <h2>
                <li>Fase Finale</li>
              </h2>
              Verranno creati i tabelloni delle eliminatorie nella seguente maniera:
              <ul>
                <li>Team Girone A - Team Girone C</li>
                <li>Team Girone B - Team Girone D</li>
              </ul>
              <br />
              Le partite da disputare saranno: 2 semifinali e 1 finale.
              <br />
              <br />
              Per la semifinale:
              <ul>
                <li>Sarà giocato un incontro diretto BO1 (solo andata)</li>
                <li>La mappa verrà scelta dal Map Pool dal team che possiede meno ex-CCP in rosa.</li>
                <li>In caso di stesso numero di ex-CCP, ci si potrà mettere daccordo sulla scelta della mappa, se non é possibile si giocherà su Mega-Random.</li>
              </ul>
              <br />
              Per la finale:
              <ul>
                <li>Sarà giocato BO3 (al meglio di 3 partite)</li>
                <li>La mappa del primo incontro verrà scelta dal Map Pool dal team che possiede meno ex-CCP in rosa.</li>
                <li>La mappa del secondo incontro sarà scelta dal perdente del primo incontro (Mappa all'interno del pool, mappa del primo incontro esclusa).</li>
                <li>Qualora si dovesse tenere, la mappa del terzo incontro sarà scelta dal perdente del secondo incontro (Mappa all'interno del pool, mappa del primo e secondo incontro escluse).</li>
              </ul>
              <br />
              <h2><li>Lobby di gioco</li></h2>
              Per creare la lobby di gioco, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>MultiPlayer</li>
                <li>Personalizzata</li>
                <li>Crea Partita</li>
                <li>Reset</li>
                <li>Permetti spettatori: nessun ritardo</li>
                <li>Map setup</li>
                <li>Cambia Mappa</li>
                <li>Seleziona Mappa</li>
                <li>Indietro</li>
                <li>Map Size: Grande (Max. 6 giocatori)</li>
                <li>Invita giocatore</li>
                <li>Aggiungi</li>
                <li>Scrivere il nome del proprio avversario</li>
                <li>Clicca Ricerca</li>
                <li>Selezionare l'icona invita nel gruppo a destra del nome del giocatore</li>
              </ol>
              <br />
              In caso di crash/disconnessione di un giocatore è consentito il restart fino al 5° minuto.
              <br />
              Se una squadra/giocatore non dovesse presentarsi all'incontro in orario, l'incontro viene assegnato come perso.
              <br />
              <br />
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default MemorialCCP;
