import React from "react";

class Crown extends React.Component {
  render() {
    if (this.props.win === "1") {
      return (<img
          class="winner-icon"
          src="https://www.aoe2.net/assets/images/winner.png"
          height="25px"
          width="25px"
          alt="Winner"
          title="Winner"
        ></img>);
    } else return null;
  }
}

export default Crown;
