import React from "react";
import { Container, Alert, Image } from "react-bootstrap";
import connection from "../images/connections.png"

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { profile: [], connections: [] };
  }

  getProfile() {
    fetch(process.env.REACT_APP_BACKEND_HOST + "/api/user/profile", { method: 'GET', credentials: 'include' })
      .then((response) => response.json())
      .then((profile) =>
        this.setState({
          profile: profile,
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  getConnections() {
    fetch(process.env.REACT_APP_BACKEND_HOST + "/api/user/connections", { method: 'GET', credentials: 'include' })
      .then((response) => response.json())
      .then((connections) =>
        this.setState({
          connections: connections,
        })
      ).catch(rejected => {
        console.log(rejected);
      });
  }

  componentDidMount() {
    this.getProfile();
    this.getConnections();
  }

  render() {
    const { profile, connections } = this.state;
    return (
      <div className="Profile">
        <header className="App-header">
          <h1>Il tuo profilo</h1>
        </header>
        <header className="App-body">
          <Container>
            {profile ? (
              <div>
                {profile.avatar ?
                  <div><img alt="avatar" src={"https://cdn.discordapp.com/avatars/" + profile.id + "/" + profile.avatar + ".webp"}></img> <br /></div>
                  : <div>
                    <img alt="avatar" src="https://cdn.discordapp.com/embed/avatars/0.png"></img>
                  </div>}
                Discord: {profile.username}#{profile.discriminator} <br />
                Email: {profile.email} <br /><br />
              </div>
            ) : <div>
            </div>
            }
            {connections.length > 0 ?
              connections.map(connection => {
                const {
                  profileId,
                  name,
                  type,
                } = connection;
                return (
                  <div>
                    <hr />
                    Profilo {type}: {name}
                    <br />
                    {profileId ? <div>Profilo AoE: {profileId}<br /><br /><Alert
                      variant="success"
                    >
                      Profilo connesso correttamente.</Alert>
                      <Alert
                        variant="warning"
                      >I dati delle tue partite verranno aggiornati ogni mezzora.<br />
                        Assicurati di essere connesso in un canale vocale pubblico del nostro
                        <a
                          href="http://discord.aoeitalia.com"
                          target="_blank"
                          rel="noreferrer"
                        >Discord</a> per almeno 30 minuti, affinchè i tuoi dati possano apparire correttamente nelle Classifiche.
                      </Alert></div> : <div> Profilo AoE: non trovato<br /><br />
                      <Alert
                        variant="warning"
                      >
                        Tra mezzora al massimo ricercheremo il tuo profilo AoE.<br />
                        Assicurati che il nome del profilo sia corretto e di avere giocato partite recentemente con questo account.<br />
                        Se questo messaggio persiste contattare lo staff.
                      </Alert></div>}
                  </div>
                );
              })
              : <Alert
                variant="danger"
              >
                Nessun account Steam o Xbox collegato su Discord.<br /><br />
                Per risolvere il problema:<br />
                <ol>
                  <li>Apri Discord</li>
                  <li>Vai su Impostazioni Utente (icona ingranaggio in basso a sinistra)</li>
                  <li>Seleziona Collegamenti (Quinto elemento in alto a sinistra)</li>
                  <li>Aggiungi il tuo profilo Steam o Xbox</li>
                  <Image src={connection} alt="connections" fluid />
                  <li>Effettua un logout e login da questo sito (in alto a destra)</li>
                </ol>
              </Alert>}
          </Container>
        </header>
      </div >
    );
  }
}

export default Profile;
