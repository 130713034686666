import React from "react";
import { useParams } from "react-router-dom";
import Countdown from 'react-countdown';


function CountdownTimerObs() {
  const { id } = useParams();
  function displayCountdown() {
    return (
      <div className="Countdown">
        <header className="App-header-countdown">
          <h1>
            <div className="font-countdown-timer">
              <Countdown date={Date.now() + id*1000}  daysInHours={true}>
              <span>poco!!!</span>
              </Countdown>
            </div>
          </h1>          
        </header>
      </div>
    );
  }

  return displayCountdown();

}

export default CountdownTimerObs;
