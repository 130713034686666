import React from "react";
import { Container, Carousel, Image } from "react-bootstrap";
import crea_lobby from "../../images/CI_crea_lobby.png";
import locandina from "../../images/CI_locandina.jpg";
import presentazione from "../../images/CI_presentazione.jpg";
import brackets from "../../images/CI_brackets.png";

class CommunityInvitational extends React.Component {
  render() {
    return (
      <div className="CommunityInvitational">
        <header className="App-header">
          <h1>Community Invitational</h1>
          <h5>(Organizzato da Kani#6212)</h5>
        </header>
        <header className="App-body">
          <Container>
            <ol>
            <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={presentazione}
                    alt="presentazione"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={brackets}
                    alt="brackets"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={locandina}
                    alt="Locandina"
                  />
                </Carousel.Item>
              </Carousel>
              {/* <iframe src="" title="CommunityInvitational" width="100%" height="500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe> */}
              <br />
              <br />
              <h2>
                <li>Descrizione</li>
              </h2>
              La Community Invitational e' un torneo 1vs1 a eliminazione diretta che si svolgerà in due serate (12 e 13 Novembre).
              <br />
              Numero di partecipanti: 16
              <br />
              Mappa: Arabia Mirror
              <br />
              Civiltà: Random/Casuali
              <br />
              Premio: 20 euro in buoni Amazon
              <br />
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              Ad ognuno dei 16 giocatori viene assegnato un numero a seconda dell'highest elo posseduto sul sito https://aoe2.net/.
              <br />
              Al giocatore con highest elo più alto viene assegnato il numero 1 e così via fino ad assegnare il numero 16 al giocatore con highest elo più basso.
              <br />
              Lo Staff potrà modificare l'highest elo di un giocatore se questo non dovesse rispecchiare la realtà.
              <br />
              <br />
              <h2>
                <li>Formato Torneo</li>
              </h2>
              Verrà creato il tabellone delle eliminatorie nella seguente maniera:
              <ul>
                <li>1 - 9</li>
                <li>5 - 13</li>
                <li>3 - 11</li>
                <li>7 - 15</li>
                <li>2 - 10</li>
                <li>6 - 14</li>
                <li>4 - 12</li>
                <li>8 - 16</li>
              </ul>
              Le partite da disputare saranno: 8 ottavi, 4 quarti, 2 semifinali e 1 finale.
              <br />
              Sarà giocato un incontro diretto BO1 (solo andata) per ottavi, quarti e semifinale.
              <br />
              La finale invece sarà BO3 (al meglio di 3 partite).
              <br />
              La mappa é totalmente simmetrica, in maniera tale che ogni giocatore abbia la sua metà di mappa uguale a quella del suo avversario.
              <br />
              La mappa é stata creata appositamente per permettere ad 8 iscritti di giocare quattro partite 1vs1 contemporaneamente.
              <br />
              All'interno della mappa "Enorme" sono presenti 4 mappe "Piccole" 1vs1 separate da una fila di rocce.
              <br />
              Ai giocatori sarà permesso interagire solamente con il proprio diretto avversario.
              <br />
              La mod, disponibile direttamente nel gioco, contenente la mappa é "AoeItalia.com"
              <br />
              Viene assegnata la sconfitta a tavolino se:
              <ul>
                <li>Il giocatore non si presenta in orario (saranno concessi solamente 10 minuti di ritardo)</li>
                <li>Il giocatore utilizza le proprie unità/edifici per attaccare/convertire giocatori, che si trovano aldilà della fila di rocce</li>
                <li>Il giocatore viene disconnesso dalla partita</li>
              </ul>
              <br />
              <h2><li>Date</li></h2>
              12 Novembre:
              <ul>
                <li>ore 22:00 (+10min) si giocano i primi 4 Ottavi</li>
                <li>a seguire i secondi 4 ottavi</li>
                <li>a seguire 4 quarti</li>
                <li>a seguire 2 semifinali</li>
              </ul>
              13 Novembre:
              <ul>
                <li>ore 22:00 (+10min) Finale (BO3)</li>
              </ul>
              <br />
              Tutte le partite verranno giocate in diretta con un delay di 3 minuti.
              <br />
              L'evento sarà castato sul canale{" "}
              <a
                href="https://www.twitch.tv/aoeitalia"
                target="_blank"
                rel="noreferrer"
              >
                https://www.twitch.tv/aoeitalia
              </a>
              <br />
              <br />
              <h3><li>Lobby di gioco</li></h3>
              Per creare la lobby di gioco, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>Selezionare l'ingranaggio in alto a destra</li>
                <li>Mod</li>
                <li>Scorri Mod</li>
                <li>Scrivere in Cerca: "AoeItalia.com"</li>
                <li>Selezionare dalla lista: "AoeItalia.com"</li>
                <li>Sottoscrivi</li>
                <li>Attendere che il download sia finito</li>
                <li>Indietro</li>
                <li>Più giocatori</li>
                <li>Ospita partita</li>
                <li>Visibilità: Pubblica</li>
                <li>Consenti spettatori: Si</li>
                <li>Nascondi civiltà: No</li>
                <li>Ritardo spettatori: 3 min</li>
                <li>Crea lobby</li>
                <Image src={crea_lobby} alt="Crea lobby" fluid />
                <li>
                  In alto a destra: Copia id partita e invialo ai
                  Streamers/Casters
                </li>
                <li>Modalità di gioco: Scenario personalizzato</li>
                <li>Selezionare dalla lista: "AoeItalia - Community Invitational"</li>
                <li>Selezionare il colore tenendo conto del proprio avversario.
                  <br />
                  Il giocatore blu(1) sarà contro il giocatore rosso(2), il verde(3) contro il giallo(4) e così via.
                </li>
                <li>Selezionare civiltà: "Casuale"</li>
              </ol>
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default CommunityInvitational;
