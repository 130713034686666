import React from "react";
import { Form, Button, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import AlertDismissible from "../components/AlertDismissible";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.fromRegisterPage)
        this.state = {
          username: "",
          password: "",
          error_message_title: "Thank you!",
          error_message_variant: "success",
          error_message_text: "Please confirm the mail",
          error_message_visible: true,
          fromRegisterPage: true,
          isLoading: false,
        };
    } else
      this.state = {
        username: "",
        password: "",
        error_message_title: "",
        error_message_variant: "",
        error_message_text: "",
        error_message_visible: false,
        fromRegisterPage: false,
        isLoading: false,
      };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const body = {
      username: this.state.username,
      password: this.state.password,
    };
    let resultStatus = 0;
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/auth/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => {
        resultStatus = response.status;
        return response.json();
      })
      .then((jsonResult) => {
        console.log(resultStatus);
        switch (resultStatus) {
          case 200:
            localStorage.setItem("accessToken", jsonResult.accessToken);
            console.log(jsonResult.accessToken);
            this.props.history.push({
              pathname: "/createTeam",
            });
            break;
          case 400:
            if (jsonResult.error === "Bad Request") {
              let errors_list = jsonResult.errors.map((error, index) => {
                return (
                  <li
                    key={index}
                  >{`${error.field}: ${error.defaultMessage}`}</li>
                );
              });
              this.setState({
                error_message_variant: "danger",
                error_message_title: "Login Error!",
                error_message_text: errors_list,
                error_message_visible: true,
                isLoading: false,
              });
            } else {
              console.log(
                "this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)"
              );
            }
            break;
          case 401:
            this.setState({
              error_message_variant: "danger",
              error_message_title: "Login Error!",
              error_message_text: "Username or Password error",
              error_message_visible: true,
              isLoading: false,
            });
            break;
          case 500:
            this.setState({
              error_message_variant: "danger",
              error_message_title: "Login Error!",
              error_message_text: "Server error, try again!",
              error_message_visible: true,
              isLoading: false,
            });
            break;
          default:
            this.setState({
              error_message_variant: "danger",
              error_message_title: "Login Error!",
              error_message_text: "Server error, try again!!",
              error_message_visible: true,
              isLoading: false,
            });
            break;
        }
        this.setState({ isLoading: false });
      })
      .catch((error) =>
        this.setState({
          error_message_variant: "danger",
          error_message_title: "Login Error!",
          error_message_text: "Server error, try again later!!!",
          error_message_visible: true,
          isLoading: false,
        })
      );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("token", JSON.stringify(nextState.isLoading));
  }

  render() {
    const isLoading = this.state.isLoading;
    let button;
    if (isLoading) {
      button = (
        <Button type="submit" variant="outline-info" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading
        </Button>
      );
    } else {
      button = (
        <Button type="submit" variant="outline-info">
          Login
        </Button>
      );
    }
    return (
      <div className="LoginPage">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="username">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Username
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="text"
                placeholder="Username"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label column xs="6" sm="4" md="4" lg="4">
              Password
            </Form.Label>
            <Col xs="6" sm="4" md="4" lg="4">
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Col xs="6" sm="4" md="4" lg="4">
              <AlertDismissible
                error_message_variant={this.state.error_message_variant}
                error_message_title={this.state.error_message_title}
                error_message_text={this.state.error_message_text}
                error_message_visible={this.state.error_message_visible}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Col xs="6" sm="4" md="4" lg="4">
              {button}
            </Col>
          </Form.Group>
          <Form.Group>
            <Col xs="6" sm="4" md="4" lg="4">
              <Link to="/resetPassword">Forgot password?</Link>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default LoginPage;
