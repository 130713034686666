import React from "react";
import { Container, Image } from "react-bootstrap";
import locandina from "../../images/CC_locandina.png";

class CondottieroCup extends React.Component {
  render() {
    return (
      <div className="CondottieroCup">
        <header className="App-header">
          <h1>Condottiero Cup</h1>
          <br />
        </header>
        <header className="App-body">
          <Container>
            <Image src={locandina} alt="locandina" fluid />
            <br />
            <br />
            <ol>
              <h2>
                <li>Date Torneo</li>
              </h2>
              Termine delle iscrizioni: 5 Gennaio 2021 – Ore 19.00
              <br />
              Date Torneo: 4 e 5 Gennaio 2021 alle 21.00
              <br />
              Data Finale: 6 Gennaio alle 21.00
              <br />
              <br />
              <h2>
                <li>Cos’è Battle Royale e come si gioca</li>
              </h2>
              Una breve, ma esaustiva guida si può trovare
              <a
                href="/university/battleRoyal"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                qua
              </a>
              <br />
              <br />
              <h2>
                <li>Formato Torneo</li>
              </h2>
              Il torneo verrà giocato nella nuova modalità ufficiale di Age of
              Empires 2: “Battle Royale” che come suggerisce il nome è pensato
              al tutti contro tutti.
              <br />
              Il numero massimo di iscritti è 32. Ogni iscrizione ulteriore non
              verrà accettata.
              <br />
              I partecipanti verranno suddivisi in “x” gironi dove “x” dipenderà
              dal numero delle iscrizioni.
              <br />
              <br />
              <h3>Qualificazioni</h3>
              I giocatori iscritti verranno raggruppati in base alla
              disponibilità oraria dei partecipanti, con un minimo di 4 fino ad
              un massimo di 8 giocatori per girone. Dato il tetto di 32
              partecipanti, ne consegue che ci potranno essere al massimo 4
              gironi.
              <br />
              <br />
              <h3>Girone Finale</h3>
              In base al numero di partecipanti, i primi 4 o i primi 2
              classificati per girone avranno accesso al girone finale.
              <br />
              <br />
              <h3>Sistema A Punti</h3>
              All’interno di ogni girone verranno disputate 5 partite, dalla
              durata di 15 minuti ciascuna.
              <br />
              Verranno giocate in ordine le seguenti mappe: Caduta di Roma,
              Caduta di Aksum, Battaglia sul Ghiaccio, Impero Majapahit, El
              Dorado.
              <br />
              Al termine di ogni partita verranno assegnati dei punti in base
              alla posizione raggiunta, seguendo questo sistema:
              <br />
              <br />
              1° posto = 8 punti
              <br />
              2° posto = 7 punti
              <br />
              3° posto = 6 punti
              <br />
              4° posto = 5 punti
              <br />
              5° posto = 4 punti
              <br />
              6° posto = 3 punti
              <br />
              7° posto = 2 punti
              <br />
              8° posto = 1 punto
              <br />
              <br />
              Al termine delle 5 partite verrà stilata una classifica finale
              dove il punteggio di ogni singolo giocatore sarà determinato dalla
              somma dei punteggi ottenuti nelle singole partite precedenti.
              <br />
              In caso di parità tra 2 o più giocatori per determinare l’ordine
              della classifica verrà fatta una partita tra i suddetti giocatori
              su BR “Caduta di Roma”. La scelta della civiltà seguirà sempre il
              criterio “Elo”.
              <br />
              <br />
              <h3>Draft Civiltà</h3>
              All’interno dei gironi, non ci saranno restrizioni di Elo,
              tuttavia sarà presente un draft di civiltà che seguirà la seguente
              modalità:
              <br />
              Verrà controllato l’Elo massimo raggiunto nella coda classificata
              1 vs 1 da ciascun giocatore sul sito www.aoe2.net.
              Successivamente, ad ogni giocatore verrà assegnato un valore
              (chiamato “Elo”) pari al proprio elo massimo raggiunto. Qualora un
              giocatore non avesse disputato alcuna partita (o non abbia
              abbastanza partite da risultare visibile nella classifica) otterrà
              un valore pari a 1000 punti.
              <br />
              <br />
              – I giocatori verranno poi ordinati in base al valore “Elo”
              ottenuto. In caso di parità, il giocatore con più partite
              disputate verrà collocato in una posizione più alta. Nel caso ci
              fossero 2 o più partecipanti con “Elo” pari a 1000 verranno
              ordinati in modo casuale.
              <br />
              – A partire dal giocatore con “Elo” più basso, a turno, ogni
              partecipante potrà selezionare la propria civiltà. Unica regola:
              non è possibile selezionare una civiltà già scelta da un altro
              giocatore. Il draft verrà eseguito per ognuna delle 5 partite (e
              mappe).
              <br />
              <br />
              <h2>
                <li>Dettagli aggiuntivi</li>
              </h2>
              Il torneo è pensato per far divertire i suoi partecipanti e al
              contempo stesso creare della sana competizione in questa nuova
              modalità
              <br />
              Non sarà tollerata in alcun modo ogni forma di discriminazione o
              forma offensiva verbale o scritta. Rispettate lo staff e i vostri
              avversari! Pena la squalifica immediata.
              <br />
              In caso di controversie, lo staff si riserva la possibilità di
              prendere decisioni non previste espressamente nel regolamento, per
              il corretto completamento del torneo.
              <br />
              In caso di disconnessione da parte di un giocatore, la partita
              continuerà ad essere giocata e il giocatore disconnesso conterà
              come se avesse abbandonato la partita.
              <br />
              Nel caso in cui un partecipante dovesse laggare eccessivamente
              durante il torneo (sin dall’inizio del match), lo staff si riserva
              la possibilità di escluderlo dal torneo per rispetto degli altri
              partecipanti.
              <br />
              <br />
              <h2>
                <li>Streaming</li>
              </h2>
              Tutte le partite saranno castate in diretta sul canale della
              community:{" "}
              <a
                href="https://www.twitch.tv/aoeitalia"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                https://www.twitch.tv/aoeitalia
              </a>
              <br />
              <br />
              <h2>
                <li>Prize pool</li>
              </h2>
              Per ogni 8 iscritti, verrà aggiunto al prizepool 1 premio*:
              <br />
              <br />
              Visto che il massimo di partecipanti è 32, significa che potranno
              esserci fino ad un massimo di 4 premi, da distribuire ai primi 4
              classificati nel girone Finale.
              <br />
              *a scelta del vincitore 1 tra:
              <br />
              – 1 x copia di “Lord of the West”, la nuova espansione che uscirà
              il 26 Gennaio 2021!
              <br />
              – 1 x copia di Age of Empires 2: Definitive Edition
              <br />
              Dettagli prize pool: da 1 a 15 iscritti è previsto 1 premio, da 16
              a 23 iscritti 2 premi, da 24 a 31 iscritti 3 premi, 32 iscritti 4
              premi.
              <br />
              <br />
              <h2>
                <li>Procedura d’iscrizione</li>
              </h2>
              Affinché l’iscrizione sia valida, dovranno essere soddisfatti i
              seguenti requisiti:
              <br />
              – Essere membri del server Discord “Age of Empires Italia”.
              <br />
              In caso contrario, sarà sufficiente creare gratuitamente un
              account Discord utilizzando questo invito:
              <a
                href="https://discord.me/ageofempiresitalia"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                https://discord.me/ageofempiresitalia
              </a>
              <br />
              – Scrivere nella sezione “Eventi server” di Discord la propria
              adesione fornendo il proprio profilo su aoe2.net e specificando
              anche la fascia oraria in cui si sarebbe disponibili.
              <br />
              (Es. Ciao, mi iscrivo! Ecco il mio profilo
              <a
                href="https://aoe2.net/#profile-4224132"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                https://aoe2.net/#profile-4224132
              </a>{" "}
              . Io posso il 5 e 6 Gennaio dalle 22.00 fino a mezzanotte!)
              <br />
              Lo staff sarà disponibile nella sezione “Supporto-server” per
              risolvere ogni eventuale dubbio o problema tecnico.
              <br />
              Un giocatore può iscriversi una sola volta.
              <br />
              La lista degli iscritti, dei gironi e le classifiche saranno
              disponibili presso questo
              <a
                href="https://docs.google.com/spreadsheets/d/1lk7taqzpaIdaXCIeALGJ_jsJ85iEoNuai8LUXf1vhP8/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                documento
              </a>
              <br />
              Iscrivendosi al torneo, il partecipante conferma la presa visione
              e l’accettazione dell’intero regolamento.
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default CondottieroCup;
