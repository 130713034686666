import React from "react";
import { Container, Image } from "react-bootstrap";
import logo from "../../images/ACL_logo.png";


class ChampionsLeague extends React.Component {
  render() {
    return (
      <div className="ChampionsLeague">
        <header className="App-header">
          <h1>Aoeitalia Champions League</h1>
          <h5>Age of Empires 4</h5>
          <h5>(Organizzato da Kani#6212)</h5>
        </header>
        <header className="App-body">
          <Container>
            <Image src={logo} alt="logo" fluid center />
            <iframe src="https://challonge.com/it/AoeItaliaChampionsLeague/module" title="Champions League - Fase a Gironi" width="100%" height="1500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
            <ol>
              <br />
              <br />
              <h2>
                <li>Descrizione</li>
              </h2>
              Aoeitalia Champions League è un torneo 1vs1 diviso in due fasi:
              <ol>
                <li>Fase a Gironi: scontri diretti tra tutti i partecipanti di uno stesso girone.</li>
                <li>Fase Finale: semifinali ad eliminazione diretta in cui si qualifica un partecipante per ogni girone.</li>
              </ol>
              <br />
              Numero massimo di partecipanti: 16
              <br />
              Numero massimo di gironi: 4
              <br />
              Numero massimo di partecipanti in un girone: 4
              <br />
              Map pool: Lipany, Altai, Arabia Arida, Monti e valli, MegaRandom
              <br />
              Draft mappe:{' '}
              <a
                href="https://aoe2cm.net/preset/OCTyA"
                target="_blank"
                rel="noreferrer"
              >
                https://aoe2cm.net/preset/OCTyA
              </a>
              {' '}(Ognuno bannerà due mappe. Quindi rimarrà solo la mappa su cui giocare.)
              <br />
              Civiltà: Nessuna Restrizione
              <br />
              Draft civiltà:{' '}
              <a
                href="https://aoe2cm.net/preset/kZvdc"
                target="_blank"
                rel="noreferrer"
              >
                https://aoe2cm.net/preset/kZvdc
              </a>
              {' '}(Il draft viene usato solo per nascondere la civiltà all'avversario)
              <br />
              Brackets:{' '}
              <a
                href="https://challonge.com/it/AoeItaliaChampionsLeague"
                target="_blank"
                rel="noreferrer"
              >
                https://challonge.com/it/AoeItaliaChampionsLeague
              </a>
              <br />
              <br />
              <h2><li>Date</li></h2>
              Domenica 11 Settembre 2022:
              <ul>
                <li>ore 15:00 - Prima partita della Fase a Gironi</li>
                <li>ore 16:30 - Seconda partita della Fase a Gironi</li>
                <li>ore 18:00 - Terza partita della Fase a Gironi</li>
              </ul>
              <br />
              Domenica 18 Settembre 2022:
              <ul>
                <li>ore 15:00 - le 2 Semifinali in contemporanea</li>
                <li>ore 16:30 - Finale (BO3)</li>
              </ul>
              <br />
              Il ritardo massimo sarà di 15 minuti.
              <br />
              E’ possibile mettersi d'accordo con il giocatore avversario, per fissare date e orari alternativi.
              <br />
              Le partite devono essere giocate nella settimana corrente, quindi non è possibile organizzare partite in date e orari successivi a quella predefinita.
              <br />
              E’ possibile giocare le tre partite in giorni diversi.
              <br />
              Comunicate le eventuali nuove date e orari degli incontri delle partite nell'apposito canale testuale del Discord.
              <br />
              In caso di mancati accordi, la data delle partite sarà quella predefinita.
              <br />
              <br />
              <h2>
                <li>Qualificazioni</li>
              </h2>
              I 16 giocatori che potranno partecipare al torneo sono:
              <ul>
                <li>
                  8 giocatori del nostro server discord con hidden elo più alto:
                  <ol>
                    <li>V1RuS-eXpOrT</li>
                    <li>CECCAZAR (Ripescato Sparta)</li>
                    <li>Gorat</li>
                    <li>rajAs</li>
                    <li>gattoratto3354</li>
                    <li>Polenta - (Ripescato white_slayer)</li>
                    <li>ChiattoneDevastante</li>
                    <li>SmearyNeptune29</li>
                  </ol>
                </li>
                <li>
                  8 giocatori che supereranno le {' '}<a
                    href="/tornei/qualificazioniChampionsLeague"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Qualificazioni Champions League
                  </a>
                  <br />
                </li>
              </ul>
              <br />
              <br />
              <h2>
                <li>Valutazione Rating</li>
              </h2>
              Ad ogni partecipante viene assegnato un numero a seconda dell'Hidden Elo (Partita Classificata 1vs1) posseduto sul sito https://aoeitalia.com/classifiche/aoe4
              <br />
              Al partecipante con Hidden Elo più alto viene assegnato il numero 1 e così via fino ad assegnare il numero 16 al team con Hidden Elo più basso.
              <br />
              Lo Staff potrà modificare l'Hidden Elo di un partecipante se questo non dovesse rispecchiare la realtà.
              <br />
              <br />
              <h2>
                <li>Fase a Gironi</li>
              </h2>
              Nella prima fase, i partecipanti saranno divisi in quattro gironi, a seconda dell'Hidden Elo.
              <ul>
                <li>
                  Girone A: partecipanti con Valutazione Rating uguale a 1,5,9,13
                </li>
                <li>
                  Girone B: partecipanti con Valutazione Rating uguale a 2,6,10,14
                </li>
                <li>
                  Girone C: partecipanti con Valutazione Rating uguale a 3,7,11,15
                </li>
                <li>
                  Girone D: partecipanti con Valutazione Rating uguale a 4,8,12,16
                </li>
              </ul>
              <br />
              Ogni partecipante dovrà quindi disputare in totale 3 partite, ovvero un incontro diretto BO1 (solo andata) per ogni avversario del proprio girone.
              <br />
              <br />
              La mappa verrà scelta dal Map Pool dal giocatore che possiede Hidden Elo più basso.
              <br />
              Per ogni partita verranno assegnati dei punti:
              <ul>
                <li>vincente: 1 punto</li>
                <li>perdente: 0 punti</li>
              </ul>
              <br />
              Alla fine di tutte le partite:
              <ul>
                <li>I partecipanti al primo posto si qualificheranno alla Fase Finale Platino.</li>
                <li>I partecipanti al secondo posto si qualificheranno alla Fase Finale Oro.</li>
                <li>I partecipanti al terzo posto si qualificheranno alla Fase Finale Argento.</li>
                <li>I partecipanti al quarto posto si qualificheranno alla Fase Finale Bronzo.</li>
              </ul>
              <br />
              In caso di stesso numero di punti, verrà considerato lo scontro diretto e successivamente Hidden Elo più alto.
              <br />
              <br />
              Nota bene: Il numero di partecipanti per girone o il numero delle partite potrebbe variare a seconda del numero degli iscritti.
              <br />
              <br />
              <h2>
                <li>Fase Finale</li>
              </h2>
              Verranno creati i tabelloni delle eliminatorie nella seguente maniera:
              <ul>
                <li>giocatore Girone A - giocatore Girone C</li>
                <li>giocatore Girone B - giocatore Girone D</li>
              </ul>
              <br />
              Le partite da disputare saranno: 2 semifinali e 1 finale.
              <br />
              <br />
              Per la semifinale:
              <ul>
                <li>Sarà giocato un incontro diretto BO1 (solo andata)</li>
                <li>La mappa verrà scelta dal Map Pool dal giocatore che possiede Hidden Elo più basso.</li>
              </ul>
              <br />
              Per la finale:
              <ul>
                <li>Sarà giocato BO3 (al meglio di 3 partite)</li>
                <li>La mappa del primo incontro verrà scelta dal giocatore che possiede Hidden Elo più basso.</li>
                <li>La mappa del secondo incontro sarà scelta dal perdente del primo incontro (Mappa all'interno del pool, mappa del primo incontro esclusa).</li>
                <li>Qualora si dovesse tenere, la mappa del terzo incontro sarà scelta dal perdente del secondo incontro (Mappa all'interno del pool, mappa del primo e secondo incontro escluse).</li>
              </ul>
              <br />
              <h2><li>Lobby di gioco</li></h2>
              Per creare la lobby di gioco, dal menu principale seguire i
              seguenti passi:
              <ol>
                <li>MultiPlayer</li>
                <li>Personalizzata</li>
                <li>Crea Partita</li>
                <li>Reset</li>
                <li>Permetti spettatori: nessun ritardo</li>
                <li>Map setup</li>
                <li>Cambia Mappa</li>
                <li>Seleziona Mappa</li>
                <li>Indietro</li>
                <li>Map Size: Piccola (Max. 2 giocatori)</li>
                <li>Invita giocatore</li>
                <li>Aggiungi</li>
                <li>Scrivere il nome del proprio avversario</li>
                <li>Clicca Ricerca</li>
                <li>Selezionare l'icona invita nel gruppo a destra del nome del giocatore</li>
              </ol>
              <br />
              In caso di crash/disconnessione di un giocatore è consentito il restart fino al 5° minuto.
              <br />
              Se una squadra/giocatore non dovesse presentarsi all'incontro in orario, l'incontro viene assegnato come perso.
              <br />
              <br />
              <br />
            </ol>
          </Container>
        </header>
      </div>
    );
  }
}

export default ChampionsLeague;
