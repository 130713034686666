import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import routes from "./routes";
import { createStore } from "redux";
import rootReducers from "./reducers";
import "./index.css";

const store = createStore(
  rootReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const rootElement = document.getElementById("root");
ReactDOM.render(<Provider store={store}>{routes}</Provider>, rootElement);
