import React from "react";
import { Container } from "react-bootstrap";

class ModsPage extends React.Component {
  render() {
    return (
      <div className="ModsPage">
        <header className="App-header">
          <h1>Guide e Mods</h1>
          <h5>(Sezione In Aggiornamento)</h5>
        </header>
        <header className="App-body">
          <Container>
            <h2>Primi Passi: Video Introduttivi</h2>
            Per chi si avvicina per la prima volta ad Age of Empires o agli RTS
            in generale.
            <ul>
              <li>
                {" "}
                Age of Empires II: HD - Guida ~ Basi del gioco (2015):{" "}
                <a
                  href="https://youtu.be/gqywQpJiWAg"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
              <li>
                {" "}
                Age of Empires II: HD - Guida ~ I tasti di scelta rapida &
                partita d'allenamento (2016):{" "}
                <a
                  href="https://youtu.be/Rdgu0PEaw1o"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
              <li>
                {" "}
                Age of Empires II: HD - Guida ~ Esplorazione, cacciagione &
                protezione (2016):{" "}
                <a
                  href="https://youtu.be/0wNkO1NDI-M"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
              <li>
                {" "}
                Age of Empires II: DE - Serata Coaching, consigli per i nuovi
                giocatori! (2020):{" "}
                <a
                  href="https://www.twitch.tv/videos/763450191"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
            </ul>
            <h2>Primi Passi: Guide Cartecee</h2>
            <ul>
              <li>
                {" "}
                Tutorial basilare sui counter delle unità (by LordShinysoul,
                2018):{" "}
                <a
                  href="https://bit.ly/3qOFS4F"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
              <li>
                {" "}
                Foglio di riferimento per una panoramica generale delle civiltà
                (by EOG & Kamigawa, 2019):{" "}
                <a
                  href="http://bit.ly/3phCpv5"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
            </ul>
            <h2>I Build Orders: Guide In Inglese Aggiornate</h2>
            <ul>
              <li>
                {" "}
                Build Order Guide:{" "}
                <a
                  href="https://buildorderguide.com/#/"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
              <li>
                {" "}
                AoE Companion:{" "}
                <a
                  href="https://aoecompanion.com/build-guides"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
            </ul>
            <h2>I Build Orders: Video Medio/Avanzati</h2>
            <ul>
              <li>
                {" "}
                Archer Rush per Arabia/mappe aperte | Flank position (2017):{" "}
                <a
                  href="https://youtu.be/BCfpa9vB8ew"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
              <li>
                {" "}
                Boom 4 TC per Arena/BF/mappe chiuse | Pocket position (2017):{" "}
                <a
                  href="https://youtu.be/vZoH7yVc_PM"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
              <li>
                {" "}
                Knight Rush [Doppia Scuderia] per Arabia/mappe aperte | Pocket
                position (2017):{" "}
                <a
                  href="https://youtu.be/PiyhYxcyGLE"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
              <li>
                {" "}
                M@A Arcieri per Arabia | 1v1/TG Flank (2017):{" "}
                <a
                  href="https://youtu.be/2pnY8MX-Twc"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </li>
            </ul>
            <h2>Le Mod Consigliate</h2>
            <ul>
              <li>
                {" "}
                Small Trees. Sostituisce tutti gli alberi con versioni
                visivamente più piccole.
              </li>
              <li> No Stumps. Nessun ceppo.</li>
              <li>
                {" "}
                No more plants. Rimuove tutte quelle piccole piante che
                influenzano solo visivamente il gioco.
              </li>
              <li>
                {" "}
                Fish Borders. Aggiunge un bordo quadrato bianco permanente a
                tutti i pesci per renderli un po' più facili da individuare.
              </li>
              <li> Zetnus Improved Grid Mod. Griglia più leggera.</li>
            </ul>
            <h2>Altre Mod</h2>
            <ul>
              <li>
                {" "}
                Smooth Ground Textures with Grid NoSnow. Semplifica il terreno e
                sostituisce la neve con la classica erba.
              </li>
              <li>
                {" "}
                Even Smaller Trees. Rende gli alberi ulteriormente più piccoli
                rispetto alla classica Pussywoods.
              </li>
              <li>
                {" "}
                Improved Tech Tree UI Mod. Inserisce il tech tree semplificato
                della civiltà corrente nel pannello in basso al centro dello
                schermo.
              </li>
              <li>
                {" "}
                Idle Villager Pointer. Aggiunge un punto esclamativo su tutte
                gli abitanti del villaggio inattivi.
              </li>
              <li>
                {" "}
                Original Sounds. Sostituisce alcuni suoni con i classici delle
                versioni pre-DE.
              </li>
              <li> No Intro. Elimina la intro all'avvio.</li>
              <li>
                {" "}
                UU Emblems. Sostituisce gli emblemi delle civiltà negli score
                con l'unità unica della civiltà.
              </li>
              <li>
                {" "}
                Glass UI. Rende trasparente tutto ciò che non è utile nella
                interfaccia utente in gioco.
              </li>
              <li>
                {" "}
                Age of Empires II DE: Age 1 DE Anniversary 2020. Skins e suoni
                del primo AoE.
              </li>
              <li>
                {" "}
                2020 Spring - Player Colors. Cambia i colori dei giocatori.
              </li>
            </ul>
          </Container>
        </header>
      </div>
    );
  }
}

export default ModsPage;
