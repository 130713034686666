import React from "react";
import { Container } from "react-bootstrap";


class Gloria2 extends React.Component {
  render() {
    return (
      <div className="Gloria2">
        <header className="App-header">
          <h1>Torneo della Gloria 2</h1>
          <h5>(Organizzato da EOG#4417)</h5>
          <h5>Age of Empires IV</h5>
        </header>
        <header className="App-body">
          <Container>
            <iframe
              src={"https://docs.google.com/document/d/e/2PACX-1vSypmXw4T_c7Szi4m6Gwh8m4O_JGsUSRuWB1UhEvP7jVPwup6T_sEFUTrpqe8HDp9sD5OpH-1ecq508/pub?embedded=true"}
              title="Torneo della Gloria 2"
              width="100%"
              height="600"
            ></iframe>
          </Container>
        </header>
      </div>
    );
  }
}

export default Gloria2;
